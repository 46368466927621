/* eslint-disable sonarjs/cognitive-complexity, react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Colorwash from '../components/Colorwash';
import Header from '../components/header/Header';
import HeaderTags from '../components/header/HeaderTags';
import Pagination from '../components/pagination/Pagination';
import { DEALS_LAYOUT } from '../config/constants/layout-types';
import {
  TYPE_CATEGORY_DEAL,
  HYPER_LOCAL_SEO_META_TITLE,
} from '../config/text/text';
import { hyperLocalSeo } from '../helpers/hyperLocalSeo';
import { replaceImageServer } from '../helpers/image';
import { getPageTitle, getMetaDescription } from '../helpers/metadata';
import { parseWowcherPath } from '../helpers/url';
import { ProductIdProvider } from '../providers/ProductIdProvider';
import { getClientSideLayoutEssentials } from '../redux/actions/clientRender';

const DealsLayout = ({
  children,
  title,
  description,
  countdown,
  titletype,
}) => {
  const [
    mainDeal,
    facetedNavigation,
    location,
    navigation,
    locations,
    url,
    user,
  ] = useSelector((state) => [
    state.deals.mainDeal,
    state.deals.facetedNavigation,
    state.locations.location,
    state.navigation.list || [],
    state.locations.locations || [],
    state.deals.canonicalUrl || '',
    state.user,
  ]);
  const router = useRouter();
  const { details } = parseWowcherPath(router.asPath);

  const productId = Number.parseInt(details?.productId);

  const filteredProduct = mainDeal?.products?.filter(
    (product) => product.id === productId,
  );
  let imageUrl = null;
  if (mainDeal?.id && mainDeal?.images?.length > 0) {
    imageUrl = replaceImageServer({
      dealId: mainDeal?.id,
      image: mainDeal?.images[0],
    });
  }

  const dispatch = useDispatch();
  const generatedTitle = titletype?.length
    ? getPageTitle({
        aspath: router.asPath,
        facetedNavigation,
        locationnameAux: location.name,
        locations,
        navigation,
        pageTitle:
          productId && filteredProduct?.length > 1
            ? filteredProduct[0].friendlyName
            : mainDeal.pageTitle,
        query: router.query,
        shortnameAux: location.shortName,
        titletype,
      })
    : '';

  let generatedDescription = description?.length
    ? description
    : getMetaDescription(
        facetedNavigation,
        router.asPath,
        location.shortName,
        location.name,
        navigation,
        locations,
      );

  let titleAux = title && title.length ? title : generatedTitle;
  if (titletype === TYPE_CATEGORY_DEAL) {
    titleAux = generatedTitle;
    if (mainDeal.title) {
      generatedDescription = mainDeal.title;
    }
  }

  if (typeof window !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    const page = query.get('page');
    if (page) {
      titleAux = `Page ${page} - ${titleAux}`;
    }
    if (details?.isHyperlocal) {
      const { hyperLocalSeoTitle, hyperLocalSeoText } = hyperLocalSeo(
        router,
        facetedNavigation,
      );

      if (hyperLocalSeoTitle || hyperLocalSeoText) {
        titleAux = HYPER_LOCAL_SEO_META_TITLE(hyperLocalSeoTitle);
        generatedDescription = hyperLocalSeoText;
      }
    }
    if (productId && filteredProduct?.length > 0) {
      titleAux = filteredProduct[0]?.friendlyName;
    }
  }

  const canonicalUrl = useMemo(() => {
    const isEvergreen = router.query.slug?.includes('e');
    if (url && isEvergreen) {
      // expected URL-format: deal/[location]/[cat]/[sub-cat]/e/evergreen-name
      const { protocol, host } = new URL(url);

      return `${protocol}//${host}${router.asPath.split('?')[0]}`;
    } else {
      return url;
    }
  }, [router, url]);

  useEffect(() => {
    dispatch(getClientSideLayoutEssentials(user, DEALS_LAYOUT));
  }, [dispatch, user.isAuthenticated, router.asPath]);

  return (
    <ProductIdProvider>
      <HeaderTags
        canonicalUrl={canonicalUrl}
        description={generatedDescription}
        imageUrl={imageUrl}
        mainDeal={mainDeal}
        path={router.asPath}
        productId={productId}
        title={titleAux}
      />
      <Colorwash />
      <Header path={router.asPath} />
      <div className="app" id="main-view">
        {children}
      </div>
      <Pagination />
    </ProductIdProvider>
  );
};

DealsLayout.propTypes = {
  children: PropTypes.node,
  countdown: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.string,
  title: PropTypes.string,
  titletype: PropTypes.string,
};

DealsLayout.defaultProps = {
  children: null,
  description: '',
  title: '',
};

export default DealsLayout;
