import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import useClientSideEffect from '../../../helpers/useClientSideEffect';
import MoreButton from '../more-button/MoreButton';

const ExpanderRelative = ({
  centeredButton = false,
  children,
  className,
  contentVisible = true,
  marginBottom = 10,
  maxHeight = 120,
}) => {
  const contentRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);

  // Explanation: we definitely want to run this on the browser only as this is purely
  // layout update. React complains about the use of useLayoutEffect with SSR.
  useClientSideEffect(() => {
    if (contentRef.current.getBoundingClientRect().height < maxHeight) {
      setExpanded(true);
    } else {
      setShowMoreButton(true);
    }
  }, [children]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div
        className={classNames('expander-container', className, {
          visible: contentVisible,
        })}
      >
        <div
          className={classNames({
            content: true,
            expanded,
          })}
          ref={contentRef}
        >
          {children}
        </div>
        {showMoreButton && (
          <div
            className={classNames('button-container', className, {
              'button-container-centered': centeredButton,
              expanded,
            })}
          >
            <MoreButton
              centeredButton
              expanded={expanded}
              onToggle={toggleExpanded}
            />
          </div>
        )}
      </div>
      <style jsx>{`
        .expander-container {
          margin-bottom: ${marginBottom}px;
          visibility: hidden;
        }

        .expander-container.visible {
          visibility: visible;
        }

        .expander-container .content {
          max-height: ${maxHeight}px;
          overflow: hidden;
        }

        .expander-container .expanded.content {
          max-height: none;
        }

        .expander-container .button-container:before {
          height: 40px;
          position: absolute;
          top: -40px;
          left: 0;
          right: 0;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          content: '';
          display: block;
        }

        .expander-container .expanded.button-container:before {
          display: none;
          position: relative;
        }

        .button-container {
          position: relative;
          top: 10px;
        }

        .button-container-centered {
          text-align: center;
          width: 100%;
        }
      `}</style>
    </>
  );
};

ExpanderRelative.propTypes = {
  centeredButton: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  contentVisible: PropTypes.bool,
  maxHeight: PropTypes.number,
};

export default ExpanderRelative;
