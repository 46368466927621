import { isLightPage } from './browser';

export const getQuality = (isSubSubCat = false) => {
  if (isLightPage()) return 35;
  if (isSubSubCat) return 60;
  if (typeof window === 'undefined' || !window) return 85;

  const urlParameters = new URLSearchParams(window.location.search);
  const adaptiveLoading = urlParameters.get('adaptiveLoading') === 'true';

  if (!adaptiveLoading) return 85;

  const connection =
    window.navigator.connection ||
    window.navigator.mozConnection ||
    window.navigator.webkitConnection;

  switch (connection.effectiveType) {
    case '2g':
      return 35;
    case '3g':
      return 60;
    case '4g':
    default:
      return 85;
  }
};
