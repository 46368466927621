import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_DEAL_IMG_WIDTH,
  DEFAULT_DEAL_IMG_HEIGHT,
  DEFAULT_DEAL_IMG_HEIGHT_MOBILE,
  DEFAULT_DEAL_IMG_WIDTH_MOBILE,
} from '../../../../config/setup/setup';
import {
  replaceImageServer,
  usePlaceholderImage,
} from '../../../../helpers/image';
import useClientSideEffect from '../../../../helpers/useClientSideEffect';
import { getIsPlaying, getManifestUrl } from '../../../../helpers/video';
import ThemeContext from '../../../../providers/ThemeProvider';
import {
  playVideo,
  setVideoVisibility,
  stopVideo,
} from '../../../../redux/actions/video';
import WrapLink from '../../../_generic/utils/WrapLink';
import DealMobileScrim from '../dealMobileScrim';
import PlayButton from './PlayButton';

const CarouselVideo = ({
  deal = {},
  href = null,
  showScrim = false,
  showControls = false,
  isClosed = false,
  onClick,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const wrapLinkHref = !onClick ? href : undefined;
  const ref = useRef();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const playing = useSelector((s) => s.video.playing);
  const canAutoPlay = useSelector((s) => s.video.autoPlayable[deal.id]);
  const isVisible = useSelector((s) => s.video.visibility[deal.id]);
  const [isActive, setIsActive] = useState(false);

  const isPlaying = getIsPlaying({
    canAutoPlay,
    deal,
    isVisible,
    playing,
  });

  // when we first switch to playing state we activate the video module
  useEffect(() => {
    if (isPlaying) {
      setIsActive(true);
    }
  }, [isPlaying]);

  // visibility
  useClientSideEffect(() => {
    const callback = debounce(() => {
      if (ref.current) {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const box = ref.current.getBoundingClientRect();
        dispatch(
          setVideoVisibility(deal.id, box.bottom > 0 && box.top < windowHeight),
        );
      }
    }, 500);

    callback();
    document.addEventListener('scroll', callback, { passive: true });

    return () => {
      document.removeEventListener('scroll', callback, { passive: true });
    };
  }, [dispatch, ref, deal.id]);

  const togglePlayback = () => {
    if (isPlaying) {
      dispatch(stopVideo(deal.id));
    } else {
      dispatch(playVideo(deal.id));
    }
  };

  // when we have native controls we need to hook into the events to set the global state
  const onPlay = () => {
    if (showControls) {
      dispatch(playVideo(deal.id));
    }
  };
  const onPause = () => {
    if (showControls && isVisible) {
      dispatch(stopVideo(deal.id));
    }
  };

  // ios will fail to play if we don't pass it the manifest directly.
  const videoSource = getManifestUrl(deal.video.videoUrl);

  return (
    <>
      <div className="deal-video__wrapper" onClick={onClick} ref={ref}>
        {!showControls && (
          <PlayButton isPlaying={isPlaying} togglePlayback={togglePlayback} />
        )}
        <WrapLink href={!showControls ? wrapLinkHref : undefined}>
          {!isActive && (
            <img
              alt={deal.images[0].alt}
              className="single-image__image"
              data-testid="placeholder_image"
              height={DEFAULT_DEAL_IMG_HEIGHT_MOBILE}
              loading="lazy"
              onError={usePlaceholderImage}
              src={replaceImageServer({
                dealId: deal.id,
                height: DEFAULT_DEAL_IMG_HEIGHT,
                image: deal.images[0],
                width: DEFAULT_DEAL_IMG_WIDTH,
              })}
              width={DEFAULT_DEAL_IMG_WIDTH_MOBILE}
            />
          )}
          {isActive && (
            <ReactPlayer
              className={classNames('deal-video', {
                'deal-video--closed': isClosed,
              })}
              config={{
                file: {
                  hlsOptions: {
                    capLevelToPlayerSize: true,
                    debug: false,
                    maxBufferLength: 4, // sec
                    maxBufferSize: 1_000 * 1_000, // 1MB
                    startLevel: 0,
                  },
                },
              }}
              controls={showControls}
              height="100%"
              loop
              muted
              onPause={onPause}
              onPlay={onPlay}
              playIcon={<div />}
              playing={isPlaying}
              playsinline
              url={videoSource}
              volume={0}
              width="100%"
            />
          )}
        </WrapLink>
      </div>
      {showScrim && <DealMobileScrim />}

      <style jsx>{`
        .deal-video__wrapper {
          position: relative;
          height: 0;
          width: 100%;
          padding-bottom: 66.9%; /* force to image ratio 777 x 520 */
          background-color: black;
          cursor: pointer;
          overflow: hidden;
        }
        :global(.deal-video) {
          position: absolute;
          top: 0;
          left: 0;
          height: 100% !important;
          width: 100%;
          display: flex;
          align-items: center;
        }
        :global(.deal-video--closed:after) {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(50, 50, 50, 0.7);
        }
        :global(.deal-video__button-listing svg) {
          color: ${theme.colors.dealvideoicon};
        }
        :global(.deal-video__button-listing) {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 999;
          background: none;
          border: none;
          filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
          padding: 0;
        }
      `}</style>
    </>
  );
};

CarouselVideo.propTypes = {
  deal: PropTypes.object,
  href: PropTypes.string,
  isClosed: PropTypes.bool,
  onClick: PropTypes.func,
  showControls: PropTypes.bool,
  showScrim: PropTypes.bool,
};

export default CarouselVideo;
