export const prepareToOpenPdf = (arrayBuffer) => {
  if (!arrayBuffer) {
    throw new Error('No data Supplied');
  }

  // IE
  if (window.navigator.msSaveOrOpenBlob) {
    return { arrayBuffer, href: null };
  }

  // all other browsers
  const file = new Blob([arrayBuffer], { type: 'application/pdf' });

  return { arrayBuffer: null, href: window.URL.createObjectURL(file) };
};

// IE only
export const openPdf = (data, fileName = 'document') => {
  if (window.navigator.msSaveOrOpenBlob) {
    const byteArray = data.slice(0); // Get array of bytes from ArrayBuffer
    window.navigator.msSaveOrOpenBlob(
      new Blob([byteArray], { type: 'application/pdf' }),
      `${fileName}.pdf`,
    );
  }
};
