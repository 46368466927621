import useSWR from 'swr';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import {
  URL_EARLY_BIRD_URL,
  URL_NEARME_POPULAR_LOCATIONS,
  URL_NEARME_POPULAR_LOCATIONS_IMG,
} from '../config/setup/setup';
import ErrorTrackingService from '../helpers/errorTrackingService';
import httpCommonHeaders from '../helpers/httpCommonHeaders';

const FALLBACK_IMAGES = [
  { imageUrl: 'https://static.wowcher.co.uk/binaries/beauty2.jpg' },
  { imageUrl: 'https://static.wowcher.co.uk/binaries/activities2.jpg' },
  { imageUrl: 'https://static.wowcher.co.uk/binaries/restaurants4.jpg' },
  { imageUrl: 'https://static.wowcher.co.uk/binaries/activities3.jpg' },
];

export const getPopularLocations = async () => {
  try {
    const response = await axios({
      cache: false,
      headers: httpCommonHeaders(),
      method: 'GET',
      url: URL_NEARME_POPULAR_LOCATIONS,
    });

    return response.data;
  } catch (error) {
    console.error(error);

    return [];
  }
};

export const getEarlyBirdDeals = async () => {
  try {
    const response = await axios({
      cache: false,
      headers: httpCommonHeaders(),
      method: 'GET',
      url: URL_EARLY_BIRD_URL,
    });

    return response.data;
  } catch (error) {
    console.error(error);

    return [];
  }
};
const fetchPopularLocationImages = async (url) => {
  try {
    const response = await axios({
      cache: false,
      headers: httpCommonHeaders(),
      method: 'GET',
      url,
    });

    return response?.data?.nearMeCategoryImagesUrl;
  } catch (error) {
    ErrorTrackingService.logError(error);
    throw new Error(error);
  }
};

export const usePopularLocationImages = (area, category) => {
  const apiUrl =
    area && category ? URL_NEARME_POPULAR_LOCATIONS_IMG(area, category) : null;
  const { data } = useSWR(apiUrl, fetchPopularLocationImages);

  return data || FALLBACK_IMAGES;
};
