import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';
import LoadingPlaceholderDeal from './LoadingPlaceholderDeal';

const TwoByTwoPlaceholder = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="container">
        <div className="deal-container">
          <div className="deal">
            <LoadingPlaceholderDeal />
          </div>
          <div className="deal">
            <LoadingPlaceholderDeal />
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .container {
            padding: 0;
          }
          .deal-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            margin: 5px 0 0 0;
          }
          .deal {
            width: 100%;
          }
          @media (min-width: ${theme.breakpoints.smUp}) {
            .deal-container {
              margin: 20px 0 0 0;
            }
            .deal {
              width: 49%;
            }
          }
        `}
      </style>
    </>
  );
};

export default TwoByTwoPlaceholder;
