import ReactCookies from 'react-cookies';
import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { GIFTING } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import { GIFTING_LINK } from '../../config/links/links';
import httpCommonHeaders from '../../helpers/httpCommonHeaders';
import { isIESite } from '../../helpers/pages';

export const setNddGiftPack = (nddGiftPack, giftWrap) => (dispatch) => {
  return dispatch({
    giftWrap,
    nddGiftPack,
    type: GIFTING.SET_NDD_GIFTING,
  });
};
export const setStdGiftPack = (stdGiftPack, giftWrap) => (dispatch) => {
  return dispatch({
    giftWrap,
    stdGiftPack,
    type: GIFTING.SET_STD_GIFTING,
  });
};
export const getGifting = () => async (dispatch) => {
  try {
    const data = await axios(GIFTING_LINK, {
      cache: false,
      headers: httpCommonHeaders({ isGPK: true }),
      method: 'GET',
    });
    const STANDARD_DELIVERY_ENABLED =
      ReactCookies.load(COOKIES.giftPackDeliveryTest) === 'a';

    if (!data || !data.data) return;

    if (STANDARD_DELIVERY_ENABLED || isIESite()) {
      dispatch(
        setStdGiftPack(data.data['std-gpk'] || {}, data.data.giftWrap || {}),
      );
    } else if (!STANDARD_DELIVERY_ENABLED) {
      dispatch(
        setNddGiftPack(data.data['ndd-gpk'] || {}, data.data.giftWrap || {}),
      );
    }

    return true;
  } catch {
    // TODO: Show the error if needed
    return false;
  }
};
