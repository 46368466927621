import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  getNearMeLinkUrlFromConfig,
  moduleSubtitle,
  moduleTitle,
  showPopularLocationModule,
} from '../../helpers/hyperlocal';
import { usePlaceholderImage } from '../../helpers/image';
import { pathLocationInfo, parseWowcherPath } from '../../helpers/url';
import { useToggle } from '../../hooks/useToggle';
import ThemeContext from '../../providers/ThemeProvider';
import { usePopularLocationImages } from '../../services/hyperlocal';

const redirectToDealPage = (url) => {
  if (typeof window !== 'undefined') {
    window.location.href = url;
  }
};

const PopularLocationsList = ({ popularLocationsData }) => {
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const locations = useSelector((state) => state.locations.locations);
  const facetedNavigation = useSelector(
    (state) => state.deals.facetedNavigation,
  );
  const [isToggled, setToggled] = useToggle();

  const {
    details: {
      category,
      subCategory,
      localDealLocation,
      isHyperlocal,
      isTravel,
    },
  } = parseWowcherPath(router.asPath);

  const { isLocationPage } = pathLocationInfo(router.asPath, locations);

  const locationShortName = localDealLocation?.startsWith('london')
    ? 'london'
    : localDealLocation;

  const images = usePopularLocationImages(locationShortName, category);

  const hyperlocalLocation = popularLocationsData?.find((entry) => {
    return entry.nearMeLocationPath === locationShortName;
  });

  const hyperlocalLocationsInCurrentArea = popularLocationsData?.filter(
    (entry) => {
      if (hyperlocalLocation) {
        return entry.areaShortName === hyperlocalLocation.areaShortName;
      } else {
        return entry.locationName === locationShortName;
      }
    },
  );

  const featuredLocations = uniqBy(
    hyperlocalLocationsInCurrentArea?.filter((entry) => entry.priority),
    'priority',
  );

  const areaName =
    hyperlocalLocation?.area ||
    locations.find((item) => item.shortName === locationShortName)?.name ||
    '';

  const categoryEntry =
    facetedNavigation?.navigations &&
    facetedNavigation.navigations.find(
      (entry) => entry.shortName === (subCategory || category),
    );

  const categoryName =
    categoryEntry?.seoFriendlyName || facetedNavigation.seoFriendlyName;

  const title = moduleTitle({
    areaName,
    categoryName,
    isHyperlocal,
    isLocationPage,
  });

  const subTitle = moduleSubtitle({ areaName, categoryName, isHyperlocal });

  const buttonToggleMessage = `View All ${areaName} locations`;

  if (
    !showPopularLocationModule({
      areaName,
      category,
      categoryName,
      hyperlocalLocationsInCurrentArea,
      isHyperlocal,
      isLocationPage,
      isTravel,
      subCategory,
    })
  ) {
    return null;
  }

  return (
    <div className="popular-locations-list">
      <h3>{title}</h3>
      <p>{subTitle}</p>
      <div className="popular-locations-list-priorities">
        {featuredLocations?.map((location, index) => {
          const url = getNearMeLinkUrlFromConfig({
            category,
            location: location.nearMeLocationPath,
            subCategory,
          });

          return (
            <div key={location.nearMeLocationName}>
              <a
                href={url}
                onClick={() => redirectToDealPage(url)}
                role="button"
              >
                <img
                  alt={location.nearMeLocationName}
                  key={location.nearMeLocationName}
                  onError={usePlaceholderImage}
                  src={images?.[index]?.imageUrl || ''}
                />
                <h4>{location.nearMeLocationName}</h4>
              </a>
            </div>
          );
        })}
      </div>
      <button onClick={setToggled} type="button">
        <span>{buttonToggleMessage}</span>
        <FontAwesomeIcon icon={isToggled ? faAngleUp : faAngleDown} size="lg" />
      </button>
      <ul className={classNames('toggle-list', { open: isToggled })}>
        {hyperlocalLocationsInCurrentArea?.map((location) => (
          <li key={location.nearMeLocationName}>
            <a
              href={getNearMeLinkUrlFromConfig({
                category,
                location: location.nearMeLocationPath,
                subCategory,
              })}
            >
              {`${categoryName || 'Deals'} in ${location.nearMeLocationName}`}
            </a>
          </li>
        ))}
      </ul>

      <style jsx>{`
        .popular-locations-list {
          font-family: 'Open Sans', sans-serif;
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
          padding: 10px;
          margin-top: 20px;
          width: 100%;
        }
        .popular-locations-list h3 {
          font-size: 19px;
        }
        .popular-locations-list h3,
        .popular-locations-list p {
          text-align: center;
        }
        .popular-locations-list button {
          color: ${theme.colors.primary};
          border: none;
          background-color: transparent;
          font-family: inherit;
          padding: 10px 0;
          cursor: pointer;
          width: 100%;
          font-weight: bold;
          font-size: 19px;
        }
        .popular-locations-list ul {
          padding: 0;
          list-style: none;
          -moz-column-count: 4;
          -moz-column-gap: 20px;
          -webkit-column-count: 4;
          -webkit-column-gap: 20px;
          column-count: 4;
          column-gap: 20px;
          border-top: 1px solid rgb(0 0 0 / 40%);
          padding-top: 10px;
        }
        .popular-locations-list ul li {
          margin-bottom: 8px;
          font-weight: bold;
        }
        .popular-locations-list ul li a {
          color: #212529;
        }
        .popular-locations-list-priorities h4 {
          margin: 8px 0;
          color: #212529;
        }

        .popular-locations-list ul li,
        .popular-locations-list-priorities h4 {
          text-transform: capitalize;
        }
        .popular-locations-list button span {
          margin-right: 8px;
        }
        .popular-locations-list-priorities {
          display: flex;
          flex-wrap: wrap;
        }

        .popular-locations-list-priorities div {
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
          flex: 25%;
          padding: 4px;
        }

        .popular-locations-list-priorities div img {
          width: 100%;
        }

        .toggle-list {
          display: none;
        }

        .toggle-list.open {
          display: block;
        }

        @media (max-width: ${theme.breakpoints.smDown}) {
          .popular-locations-list ul {
            -moz-column-count: 1;
            -webkit-column-count: 1;
            column-count: 1;
          }

          .popular-locations-list-priorities div {
            flex: 50%;
          }
        }
      `}</style>
    </div>
  );
};

PopularLocationsList.propTypes = {
  popularLocationsData: PropTypes.array.isRequired,
};

export default PopularLocationsList;
