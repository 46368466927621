import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';
import MainDealTopMessageBanner from '../MainDealTopMessageBanner';

const emptyDeal = ({
  extraClasses = '',
  isPreview = false,
  previewMessage = null,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      {isPreview && <MainDealTopMessageBanner message={previewMessage} />}
      <div className={`emptydeal ${extraClasses}`} />

      <style jsx>{`
        .emptydeal {
          margin: 0;
          padding: 0;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          background-color: ${theme.colors.emptydeal};
        }
        .small-deal {
          width: 350px;
          height: 306px;
        }
        @media (max-width: ${theme.breakpoints.mdUp}) {
          .emptydeal {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

emptyDeal.propTypes = {
  extraClasses: PropTypes.string,
  isPreview: PropTypes.bool,
  previewMessage: PropTypes.string,
};

export default emptyDeal;
