import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Cookies from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import COOKIES from '../config/cookies/cookies';
import { NUMBER_OF_DEALS } from '../config/links/links';
import { setVideoAutoPlayable } from '../redux/actions/video';
import { isLightPage } from './browser';
import { getIsOpen } from './deals';

export const showVideo = (deal) => {
  const hasVideo = deal?.video?.videoUrl;

  if (hasVideo) {
    const isInVideoTest = Cookies.load(COOKIES.videoRolloutTest) === 'B';
    if (isInVideoTest || (!isInVideoTest && deal.video.abtest === false)) {
      // show to all users in test, show to all users outside of test if not AB testing that video
      return true;
    }
  }

  return false;
};

export function useVideoScroll(deals) {
  const dispatch = useDispatch();
  const mainDeal = useSelector((store) => store.deals.mainDeal);
  const secondaryDeals = useSelector(
    (store) => store.deals.secondaryDealsArray,
  );
  const router = useRouter();

  // set auto playable deals
  useEffect(() => {
    // we don't load/autoplay if google bot
    if (isLightPage()) {
      return;
    }

    let found = false;
    const partial = {};

    if (mainDeal?.video?.videoUrl && getIsOpen(mainDeal, router.query)) {
      partial[mainDeal.id] = true;
      found = true;
    }

    if (!found) {
      for (const deal of secondaryDeals) {
        if (showVideo(deal)) {
          partial[deal.id] = true;
          found = true;
          break;
        }
      }
    }

    const flatDeals = deals?.flat() || [];
    flatDeals.forEach((deal, index) => {
      if (index % NUMBER_OF_DEALS === 0) {
        found = false;
      }
      if (!found && showVideo(deal)) {
        partial[deal.id] = true;
        found = true;
      }
    });

    dispatch(setVideoAutoPlayable(partial));
  }, [dispatch, deals, router.query]);
}

export const getIsPlaying = ({ deal, isVisible, canAutoPlay, playing }) => {
  if (!isVisible) {
    return false;
  }

  if (playing === null) {
    return canAutoPlay;
  } else {
    return playing === deal.id;
  }
};

export const getManifestUrl = (videoUrl) => {
  if (!videoUrl) {
    return '';
  }
  let videoSource = '';

  // deal with change in api
  if (videoUrl.startsWith('https://watch.videodelivery.net')) {
    const videoId = videoUrl.slice(Math.max(0, videoUrl.lastIndexOf('/') + 1));
    videoSource = `https://videodelivery.net/${videoId}/manifest/video.m3u8`;
  } else if (videoUrl.includes('cloudflarestream.com')) {
    if (videoUrl.endsWith('/watch'))
      videoUrl = videoUrl.slice(0, videoUrl.lastIndexOf('/'));
    videoSource = `${videoUrl}/manifest/video.m3u8`;
  }

  return videoSource;
};
