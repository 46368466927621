/* eslint-disable sonarjs/cognitive-complexity, complexity */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { SHOW_PRICE_TYPES } from '../../../config/constants/deals';
import { POSTAGE_AND_PACKAGING_SHORT } from '../../../config/text/text';
import { formatCurrency } from '../../../helpers/currency';
import { showPriceType } from '../../../helpers/deals';
import { isMobileViewport } from '../../../helpers/screen';
import ThemeContext from '../../../providers/ThemeProvider';

const PostageAndPackagingText = ({
  bigTile = false,
  className = '',
  deal = null,
  discount = false,
  forcePrice = true,
  isHideIfNoDiscount = true,
  isMain = false,
  isSmall = false,
  secondCheckout = false,
  show = false,
}) => {
  const theme = useContext(ThemeContext);
  const isMobile = isMobileViewport();

  const clickAndCollect = deal?.display?.clickAndCollectDeal;
  // Show reduced size for P&P text on deals page
  const isHeadlineBig = deal?.headline?.length > 58 && !isMain;

  const minProductPostagePrice =
    deal?.products?.length > 0 ? deal?.products[0].postagePrice : 0;

  const minPostagePrice =
    deal?.minPostagePrice === 0 ? minProductPostagePrice : deal.minPostagePrice;

  if (
    !clickAndCollect &&
    (!show || !deal.minPostagePrice) &&
    !secondCheckout &&
    !bigTile
  ) {
    return null;
  }

  if (
    deal?.deliveryType === 'none' ||
    (deal?.minPostagePrice === 0 && !clickAndCollect)
  ) {
    return null;
  }
  // we want to show P&P only for deals without % on splat
  const priceType = showPriceType(
    deal,
    discount,
    forcePrice,
    isHideIfNoDiscount,
  );

  const showDiscount = [SHOW_PRICE_TYPES.showDiscountIndicative].includes(
    priceType,
  );

  const isMonetaryValue = [
    SHOW_PRICE_TYPES.showPrice,
    SHOW_PRICE_TYPES.showOriginalPrice,
  ].includes(priceType);

  const showClickAndCollect =
    showDiscount && deal.display?.discountAmount && deal.discount > 0;

  // can't hide postage on second checkout
  if (!isMonetaryValue && !showClickAndCollect && !secondCheckout && !bigTile) {
    return null;
  }

  return (
    <>
      <span
        className={classNames(`pp-text`, className, {
          'pp-text--small': isSmall,
        })}
      >
        {bigTile && minPostagePrice > 0 ? (
          <div className="p-p">
            {minPostagePrice === 0 ? (
              <p className="p-p-text-non-cc">
                {/*   {POSTAGE_AND_PACKAGING_CLICK_AND_COLLECT} */}
              </p>
            ) : (
              <p className="p-p-text-non-cc">
                {`${deal.postagePriceText || '+'} ${formatCurrency(
                  deal.currency,
                  minPostagePrice,
                  true,
                  false,
                )} `}
                <span className="p-p-text__pp">
                  {POSTAGE_AND_PACKAGING_SHORT}
                </span>
              </p>
            )}
          </div>
        ) : minPostagePrice === 0 ? (
          <p className="p-p-text-non-cc">
            {/*  {POSTAGE_AND_PACKAGING_CLICK_AND_COLLECT} */}
          </p>
        ) : (
          <p className="pp-text-non-cc">
            {`${deal.postagePriceText || '+'} ${formatCurrency(
              deal.currency,
              minPostagePrice,
              true,
              false,
            )} `}
            <span className="pp-text__pp">{POSTAGE_AND_PACKAGING_SHORT}</span>
          </p>
        )}
      </span>
      <style jsx>{`
        .pp-text {
          line-height: 1em;
          font-size: ${isHeadlineBig ? '10px' : '16px'};
          color: #777777;
        }
        .pp-text__pp {
          font-size: ${isMain ? '10px' : '8px'};
        }
        .p-p-text__pp {
          font-size: 8px;
          color: '#3d4144';
        }
        .p-p-text-non-cc {
          line-height: 1em;
          font-size: 12px;
          color: '#3d4144';
          margin: 2px 0 0 0;
        }
        .pp-text-non-cc {
          line-height: 1em;
          font-size: ${isMain ? '14px' : '12px'};
          color: ${isMain
            ? '#333333'
            : secondCheckout
            ? '#ffffff'
            : isMobile
            ? '#ffffff'
            : '#777777'};
          margin: ${secondCheckout ? '5px 0 0 0' : '2px 0 0 0'};
        }
        .pp-text.inline {
          display: inline;
        }
        .pp-text.invert {
          color: white;
        }
        .pp-text.margin {
          margin-bottom: 8px;
        }
        .pp-text.display-mobile {
          display: block;
        }
        .pp-text.hide-mobile {
          display: none;
        }
        .p-p {
          width: max-content;
          margin: 0 8px 10px 0;
          padding: 0px 10px;
          background-color: ${theme.colors.pandpcolor};
          font-weight: bold;
          border-radius: 20px;
          padding-top: 2px;
          padding-bottom: 2px;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 24px;
          vertical-align: middle;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .pp-text.display-mobile {
            display: none;
          }
          .pp-text.hide-mobile {
            display: inline-block;
          }
        }
        @media (max-width: ${theme.breakpoints.smDown}) {
          .pp-text.mobile-invert {
            color: white;
          }
        }
      `}</style>
    </>
  );
};

PostageAndPackagingText.propTypes = {
  bigTile: PropTypes.bool,
  className: PropTypes.string,
  deal: PropTypes.object,
  discount: PropTypes.bool,
  forcePrice: PropTypes.bool,
  isHideIfNoDiscount: PropTypes.bool,
  isMain: PropTypes.bool,
  isSmall: PropTypes.bool,
  secondCheckout: PropTypes.bool,
  show: PropTypes.bool,
};

PostageAndPackagingText.displayName = 'PostageAndPackagingText';

export default PostageAndPackagingText;
