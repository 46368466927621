import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';
import PrimaryButton from '../../_generic/button/PrimaryButton';

const LoadPrevious = ({ onClick }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="load-previous__wrapper">
        <PrimaryButton className="full-width outline margin" onClick={onClick}>
          Load Previous
        </PrimaryButton>
      </div>
      <style jsx>{`
        .load-previous__wrapper {
          margin: 40px auto 30px;
          max-width: 1110px;
        }

        @media (max-width: ${theme.breakpoints.xlDown}) {
          .load-previous__wrapper {
            max-width: 666px;
          }
        }

        @media (max-width: ${theme.breakpoints.mdDown}) {
          .load-previous__wrapper {
            margin-left: 3rem;
            margin-right: 3rem;
          }
        }
      `}</style>
    </>
  );
};
export default LoadPrevious;
