/* eslint-disable complexity, filenames/match-exported, sonarjs/cognitive-complexity, react/forbid-component-props */
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { DEAL_LOCATION_TYPES } from '../../config/constants/page-types';
import {
  DEFAULT_DEAL_IMG_HEIGHT_MOBILE,
  DEFAULT_DEAL_IMG_HEIGHT_SECONDARY,
  DEFAULT_DEAL_IMG_WIDTH_MOBILE,
  DEFAULT_DEAL_IMG_WIDTH_SECONDARY,
  FEATURE_FLAGS,
  WISHLIST,
} from '../../config/setup/setup';
import { GO_TO_DEAL, VIEW } from '../../config/text/text';
import { initializeTracking, trackEvent } from '../../helpers/analytics';
import { createBasketAndMoveToCheckout } from '../../helpers/basketCreator';
import {
  getUrlFromDeal,
  hidePrice,
  showPostage,
  useDealImpresssion,
} from '../../helpers/deals';
import { parseWowcherPath, redirectToURL } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import Clamp from '../_generic/utils/Clamp';
import DealPriceSplat from './shared-components/DealPriceSplat';
import DealSocialCueTop from './shared-components/DealSocialCueTop';
import DealSocialCuesBottom from './shared-components/DealSocialCuesBottom';
import PostageAndPackagingText from './shared-components/PostageAndPackagingText';
import VipExclusiveTag from './shared-components/VipExclusiveTag';
import CarouselImage from './shared-components/carousel/CarouselImage';
import DealButton from './shared-components/dealButton';
import DealCardWrapper from './shared-components/dealCardWrapper';
import EmptyDeal from './shared-components/emptyDeal';

const WishlistIcon = dynamic(() => import('./shared-components/WishlistIcon'), {
  ssr: false,
});

const SecondaryDeal = ({
  deal,
  socialcues,
  extraclasses,
  hideSplat,
  isXS,
  hasSocialCueTop,
  showWishlist,
  splatClass,
}) => {
  const [
    ssr,
    location,
    mainDeal,
    trackingDeals,
    secondaryDealsArray,
    locationStore,
  ] = useSelector((state) => [
    state.deals.ssr,
    state.locations.location.shortName,
    state?.deals?.mainDeal,
    state?.deals?.secondaryDealsArray,
    state?.tracking?.trackingDeals,
    state.locations,
  ]);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userVipStatus = useSelector(
    (state) => state.user.userprofile?.userVipStatus,
  );

  const router = useRouter();
  const theme = useContext(ThemeContext);
  const imageHeight = isMobile
    ? DEFAULT_DEAL_IMG_HEIGHT_MOBILE
    : DEFAULT_DEAL_IMG_HEIGHT_SECONDARY;
  const imageWidth = isMobile
    ? DEFAULT_DEAL_IMG_WIDTH_MOBILE
    : DEFAULT_DEAL_IMG_WIDTH_SECONDARY;
  const componentRef = useRef(null);
  useDealImpresssion({ componentRef, dealId: deal.id, location });
  const isVipUser = useSelector((state) => state?.user?.userprofile?.vipUser);
  if (!deal || Object.keys(deal).length === 0) return <EmptyDeal />;

  const dealUrlPath = getUrlFromDeal({
    deal,
    originPath: router.asPath,
  });

  const dealHeadline =
    isVipUser && deal?.vipHeadline ? deal?.vipHeadline : deal?.headline;
  const headLineAux = deal.headline ? hidePrice(dealHeadline, ssr) : '';
  const hasPostage = showPostage(deal, true, true);
  const isViewAll = deal.promoType;
  const pathUrl = parseWowcherPath(router.asPath);
  const showVipExclusiveTag = isVipUser && deal?.display?.vipExclusive;
  const discount = Boolean(
    pathUrl?.details?.locationType !== DEAL_LOCATION_TYPES.local &&
      !pathUrl?.details?.isTravel,
  );

  const setScrollDataOnClick = () => {
    initializeTracking(deal, [mainDeal, ...trackingDeals]);
  };

  const redirectTo = (dealUrlPath) => {
    trackEvent('buy_again_click');
    if (
      deal?.promoType?.title === 'Buy again' &&
      deal?.buyAgainRedirectToCheckoutPage
    ) {
      createBasketAndMoveToCheckout(
        deal,
        locationStore,
        0,
        false,
        false,
        isAuthenticated,
        userVipStatus,
        router,
      );
    } else {
      redirectToURL(dealUrlPath || '', router);
    }
  };

  const reviewScore = deal.reviewSummary?.average || null;
  const reviewCount = deal.reviewSummary?.total || null;

  return (
    <div
      data-review-count={reviewCount}
      data-review-score={reviewScore}
      onClick={setScrollDataOnClick}
      ref={componentRef}
    >
      <DealCardWrapper
        className={`secondary-deal deal_item ${extraclasses}`}
        id={`deal_${deal.id}`}
      >
        <div className="deal-info-container row">
          {isViewAll && (
            <div className="deal-viewall__header">
              <h3 className="deal-viewall__text">{deal.promoType.title}</h3>
              <Link href={deal.promoType.link ?? ''}>
                <a className="deal-viewall__link" href={deal.promoType.link}>
                  {deal.promoType?.linkName}
                </a>
              </Link>
            </div>
          )}
          <div className="deal-info">
            <div className="deal-title">
              <div
                aria-label={GO_TO_DEAL.replace('##DEAL##', deal.headline)}
                onClick={() => redirectTo(dealUrlPath)}
                role="button"
                style={{ cursor: 'pointer' }}
                tabIndex={0}
              >
                <Clamp lines={isViewAll ? 2 : 3}>
                  <h3>{headLineAux}</h3>
                </Clamp>
                <PostageAndPackagingText
                  className="mobile-invert display-mobile"
                  deal={deal}
                  discount={discount}
                  show={hasPostage}
                />
              </div>
            </div>
          </div>
          {!isXS && (
            <div className="deal-button justify-content">
              <div className="button-view">
                <DealButton
                  asPath="/deal/[...slug]"
                  className="full-width"
                  onClick={() => redirectTo(dealUrlPath)}
                  type="button"
                >
                  {VIEW}
                </DealButton>
              </div>
            </div>
          )}
        </div>
        <div className="deal-image__container row">
          <div className="shared-deal-image__container">
            {!hideSplat && (
              <DealPriceSplat
                className={classNames(splatClass, { xs: isXS })}
                deal={deal}
                discount={discount}
                visible={!ssr}
              />
            )}
            {showVipExclusiveTag && <VipExclusiveTag className="size--xs" />}
            {showWishlist && FEATURE_FLAGS[WISHLIST] && (
              <WishlistIcon dealId={deal.id} left />
            )}
            {hasSocialCueTop && (
              <DealSocialCueTop
                className="position-left"
                isXS={isXS}
                order={deal.order}
                socialCueClaim={deal?.socialCueClaim}
                socialCuesAvailability={deal.totalRemaining || 0}
                socialCuesBought={deal.totalBought || 0}
                socialCuesData={socialcues}
              />
            )}
            <DealSocialCuesBottom
              discount={discount}
              hideCue
              socialCuesData={deal}
            >
              <PostageAndPackagingText
                className="mobile-invert hide-mobile"
                deal={deal}
                isSmall
                show={hasPostage}
              />
            </DealSocialCuesBottom>
            <CarouselImage
              deal={deal}
              height={imageHeight}
              image={deal.images?.[0]}
              load
              onClick={() => redirectTo(dealUrlPath)}
              showImageOverlays={false}
              width={imageWidth}
            />
          </div>
        </div>
        <style jsx>{`
          .deal-viewall__header {
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 11px;
            padding-right: 11px;
            border-bottom: 1px solid ${theme.colors.primary};
            background-color: ${theme.colors.primarypale};
            min-height: 22px;
          }
          .deal-viewall__text {
            color: ${theme.colors.primary};
            margin-bottom: 0;
            font-size: 15px;
          }
          .deal-viewall__link {
            margin-left: auto;
            display: block;
            font-size: 13px;
          }
          .deal-image__container {
            width: 100%;
            z-index: 1;
            bottom: ${isViewAll ? '-24px' : '0px'};
          }

          .deal-extra-container {
            z-index: 2;
            height: 70%;
            position: relative;
          }

          .deal-info-container {
            min-height: 65px;
            width: 100%;
            box-sizing: content-box;
            padding: 0;
            flex-direction: column;
          }

          .row {
            margin: 0px;
          }

          .deal-info {
            padding: 0;
            padding-left: 11px;
            padding-right: 11px;
            display: flex;
            align-items: center;
            width: ${isViewAll ? '100%' : 'auto'};
            flex-grow: 1;
          }

          .deal-info a h3 {
            font-size: ${isXS ? '14px' : '16px'};
            font-weight: bold;
            margin: 0;
            color: ${theme.colors.dealtext};
            //max-width: 245px;
          }

          .deal-info .deal-title {
            width: ${isViewAll ? '100%' : 'auto'};
          }

          .deal-info a {
            text-decoration: none;
          }

          .deal-info .deal-subtitle {
            margin: 0;
            font-size: 14px;
            line-height: 1.2;
            color: #000000;
            text-decoration: none;
            color: ${theme.colors.dealtext};
            font-size: 14px;
          }

          .deal-info .deal-info-container .deal-info {
            margin-top: 10px;
          }

          .deal-button {
            padding: 0;
          }

          .justify-content {
            position: absolute;
            bottom: 0;
            width: calc(100% - 90px);
            z-index: 10;
            padding-left: 10px;
            padding-right: 0;
            padding-bottom: 36px;
          }
          .deal-info .deal-title h3 {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            color: ${theme.colors.dealtext};
          }

          .button-view {
            position: absolute;
            right: 7px;
            height: 48px;
            width: 30%;
          }

          .shared-deal-image__container {
            position: relative;
            height: ${isXS ? 'auto' : '234px'};
          }

          .shared-deal-image__container .shared-deal--extra-container {
            position: relative;
            bottom: 24px;
          }

          .shared-deal-image__container img {
            width: 100%;
          }

          .social-cues-container__main-image__top {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            display: none;
          }

          .social-cues-container__main-image__top .social-cue__item {
            width: 50%;
            margin: 10px;
            text-align: center;
            background-color: ${theme.colors.primary};
            color: ${theme.colors.textonprimary};
            font-weight: bold;
            border-radius: 10px;
          }

          .deal-image__container
            :global(.social-cues-container__main-image__bottom) {
            bottom: 15px;
          }

          @media (max-width: ${theme.breakpoints.mdDown}) {
            // 768
            .shared-deal-image__container {
              width: 100%;
              height: auto;
            }
            .deal-info a h3 {
              max-width: 70%;
            }

            .button-view {
              bottom: 17px;
            }
          }
          .deal-image__link {
            display: block;
          }

          @media (max-width: ${theme.breakpoints.smDown}) {
            .deal-info-container {
              min-height: 0;
            }
            .deal-info {
              position: absolute;
              bottom: 0;
              width: 100%;
              z-index: 10;
              padding-left: 10px;
              padding-right: 0;
              padding-bottom: 36px;
            }
            .deal-info .deal-title h3 {
              color: ${theme.colors.dealmobiletitle};
              font-size: 16px;
              line-height: normal;
              font-weight: bold;
              max-width: 78%;
            }
            .button-view {
              display: none;
            }
            .shared-deal-image__container {
              width: 100%;
              height: fit-content;
            }
            .deal-viewall__header {
              border-top: 1px solid ${theme.colors.primary};
              border-bottom: none;
            }
            .deal-image__container
              :global(.social-cues-container__main-image__bottom) {
              bottom: 0;
            }
          } // 575
        `}</style>
        <style global jsx>{`
          .shared-deal-image__container .carousel:nth-child(2) {
            position: inherit;
          }

          .secondary-deal {
            position: relative;
            width: 350px;
            height: 306px;
          }

          .secondary-deal.full-width {
            width: 100%;
            min-height: 220px;
          }

          .shared-deal-image__container .carousel-root {
            //height: 100%;
          }
          .shared-deal-image__container .carousel .thumbs-wrapper {
            position: absolute;
            bottom: 0px;
            margin: 0px;
            max-width: 46%;
          }
          .shared-deal-image__container .carousel ul.thumbs {
            padding-inline-start: 0px;
            cursor: pointer;
            margin-bottom: 0px;
          }
          .shared-deal-image__container .carousel .thumb {
            width: 64px !important;
            height: auto;
          }
          .shared-deal-image__container .carousel .thumb.selected,
          .carousel .thumb {
            cursor: pointer;
          }
          .shared-deal-image__container .carousel .thumbs-wrapper {
            z-index: 4;
            margin-left: 10px;
          }
          .shared-deal-image__container .carousel .thumb.selected,
          .shared-deal-image__container .carousel .thumb:hover {
            border: 3px solid ${theme.colors.primary};
          }
          .shared-deal-image__container .carousel .control-dots li {
            box-shadow: none;
            opacity: 1;
            border: 2px solid ${theme.colors.primary};
          }
          .shared-deal-image__container .carousel .control-dots li.selected {
            background-color: ${theme.colors.primary};
          }

          .card.small-deal {
            height: 214px;
            margin: 0;
          }
          .card.small-deal-vip {
            height: 277px;
            margin: 0;
          }
          .secondary-deal
            .social-cues-container__main-image__bottom
            .social-cue__item {
            display: none;
          }
          .secondary-deal
            .social-cues-container__main-image__bottom
            .social-cue__item-child {
            display: block;
          }

          .secondary-deal .social-cues-container__main-image__top {
            display: none;
          }

          @media (max-width: ${theme.breakpoints.xlDown}) {
            //1199.98px
            // Not Ipad but too small for xl deal + small deal in the same row
            .secondary-deal {
              width: 326px;
              height: 263px !important;
              margin-bottom: 0 !important;
            }
            .secondary-deal.full-width {
              width: 100%;
            }
            .shared-deal-image__container .carousel .thumb {
              width: 60px !important;
              height: auto;
            }
            .secondary-deal .deal-image__container {
              bottom: 0 !important;
            }
          }

          @media (max-width: ${theme.breakpoints.mdDown}) {
            .secondary-deal {
              height: 233px !important;
              margin-bottom: 0 !important;
            }
          }

          @media (max-width: ${theme.breakpoints.smDown}) {
            // 575
            .card.secondary-deal {
              border-radius: 0;
              width: 100% !important;
              height: auto;
              margin-bottom: 5px !important;
            }
            .shared-deal-image__container .carousel .thumbs-wrapper {
              display: none;
            }
            .shared-deal-image__container .social-cues-container__info {
              display: none;
            }
            .shared-deal-image__container
              .social-cues-container__main-image__bottom
              .bought-count {
              background-color: transparent !important;
              color: white !important;
            }
            .shared-deal-image__container
              .social-cues-container__main-image__bottom
              .social-cue__item.availability {
              display: none;
            }
            .card.secondary-deal {
              border-radius: 0;
              height: auto !important;
              width: 100%;
              display: inline-block;
            }
            .card.secondary-deal img {
              display: block;
            }
            .social-cues-container__main-image__bottom .social-cue__item {
              padding: 0 !important;
              margin: 0 !important;
            }
            .secondary-deal .social-cues-container__main-image__top {
              display: flex;
              justify-content: center;
            }
            .secondary-deal
              .social-cues-container__main-image__bottom
              .social-cue__item {
              display: block;
            }
            .secondary-deal
              .social-cues-container__main-image__bottom
              .social-cue__item-child {
              display: none;
            }
            .card.small-deal {
              height: 214px;
              margin-bottom: 5px !important;
            }
            .card.small-deal-vip {
              height: 277px;
              margin-bottom: 5px !important;
            }
            .secondary-deal .shared-deal-image__container {
              height: auto;
            }
            .secondary-deal .deal-image__container {
              position: relative;
            }
          }

          .secondary-deal.auto-width {
            height: auto !important;
            min-height: auto !important;
            width: 100%;
          }
        `}</style>
      </DealCardWrapper>
    </div>
  );
};

SecondaryDeal.propTypes = {
  deal: PropTypes.object,
  extraclasses: PropTypes.string,
  hasSocialCueTop: PropTypes.bool,
  hideSplat: PropTypes.bool,
  isXS: PropTypes.bool,
  showWishlist: PropTypes.bool,
  socialcues: PropTypes.object,
  splatClass: PropTypes.string,
};

SecondaryDeal.defaultProps = {
  deal: null,
  extraclasses: '',
  hasSocialCueTop: true,
  hideSplat: false,
  showWishlist: true,
  socialcues: null,
  splatClass: 'top-right',
};

export default SecondaryDeal;
