import PropTypes from 'prop-types';
import React from 'react';
import {
  PAYPAL_CREDIT_OVERLAY,
  PAYPAL_CREDIT_OVERLAY_TRAVEL,
} from '../../../config/constants/images';
import { SITE_FIVEPM } from '../../../config/setup/setup';
import { isLightPage } from '../../../helpers/browser';
import { getBinaryUrl } from '../../../helpers/url';

const payPalCreditEnabled = JSON.parse(
  process.env.NEXT_PUBLIC_PAYPAL_CREDIT_ENABLED === 'true',
);

const displayPaypalOverlay = (overlay) => {
  return (
    (overlay.url !== PAYPAL_CREDIT_OVERLAY ||
      (overlay.url === PAYPAL_CREDIT_OVERLAY && payPalCreditEnabled)) &&
    (overlay.url !== PAYPAL_CREDIT_OVERLAY_TRAVEL ||
      (overlay.url === PAYPAL_CREDIT_OVERLAY_TRAVEL && payPalCreditEnabled))
  );
};

const ImageOverlays = ({ show, image }) => {
  if (isLightPage() || !show || !image || !image.imageOverlays) return null;

  const { left, right } = image.imageOverlays.reduce(
    (out, overlay) => {
      if (overlay.brand === 'wowcher') {
        if (overlay.position === 'LEFT') {
          out.left.push(overlay);
        }
        if (overlay.position === 'RIGHT' && displayPaypalOverlay(overlay)) {
          out.right.push(overlay);
        }
      }

      return out;
    },
    { left: [], right: [] },
  );

  return (
    <>
      <div className="image-overlays">
        <div className="image-overlays__content image-overlays__content--left">
          {left.map((binary, index) => {
            const url =
              process.env.NEXT_PUBLIC_SITE === SITE_FIVEPM
                ? getBinaryUrl(binary.url)
                : binary.url;

            return (
              url && (
                <img
                  alt=""
                  className="image-overlays__image"
                  key={`binary-left-${url}-${index}`}
                  loading="lazy"
                  src={url}
                />
              )
            );
          })}
        </div>
        <div className="image-overlays__content image-overlays__content--right">
          {right.map((binary, index) => {
            const url =
              process.env.NEXT_PUBLIC_SITE === SITE_FIVEPM
                ? getBinaryUrl(binary.url)
                : binary.url;

            return (
              url && (
                <img
                  alt=""
                  className="image-overlays__image"
                  key={`binary-right-${url}-${index}`}
                  loading="lazy"
                  src={url}
                />
              )
            );
          })}
        </div>
      </div>
      <style jsx>{`
        .image-overlays {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          overflow: hidden;
        }
        .image-overlays__content {
          position: absolute;
          top: 0;
          width: 45%;
          padding-top: 10%;
        }
        .image-overlays__content--left {
          left: 0;
        }
        .image-overlays__content--right {
          right: 0;
        }
        .image-overlays__image {
          max-width: 100%;
          height: auto !important;
        }
      `}</style>
    </>
  );
};

ImageOverlays.propTypes = {
  image: PropTypes.object,
  show: PropTypes.bool,
};

ImageOverlays.defaultProps = {
  image: {},
  show: false,
};

export default ImageOverlays;
