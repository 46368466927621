import classNames from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { GIFTS, GIFTS_DESCRIPTION } from '../../config/text/text';
import { getSubCatsWithPicture } from '../../helpers/facetedNavigation';
import ThemeContext from '../../providers/ThemeProvider';
import FeaturedSubcats from './FeaturedSubcats';

const FeaturedSubcatsContainer = ({ isGifts = false, specifiedSubcats }) => {
  const [facetedNavigation] = useSelector((state) => [
    state.deals.facetedNavigation,
  ]);

  const theme = useContext(ThemeContext);
  const router = useRouter();
  const path = router.asPath;

  const parsed = getSubCatsWithPicture(facetedNavigation, path);
  const resultNavigations = parsed?.length > 0 ? parsed : [];
  const navigations = specifiedSubcats || resultNavigations;

  if (resultNavigations.length === 0 && !specifiedSubcats) {
    return null;
  }

  return (
    <>
      <div className={classNames('subcats-container', { 'gifts-bg': isGifts })}>
        {isGifts && (
          <div className="gifts-subcategory-banner">
            <h3 className="gifts-title">{GIFTS}</h3>
            <p className="gifts-description">{GIFTS_DESCRIPTION}</p>
          </div>
        )}
        <div className="featured-subcategory">
          {resultNavigations && (
            <FeaturedSubcats isGifts={isGifts} navigations={navigations} />
          )}
        </div>
      </div>

      <style jsx>{`
        .subcats-container {
          width: 100%;
        }
        .gifts-bg {
          background: white;
          margin-top: 20px;
          padding-bottom: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
        }
        .gifts-subcategory-banner {
          width: 100%;
          margin: auto;
          text-align: center;
          padding: 20px 20px 0 20px;
        }
        .gifts-title {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 600;
        }

        .gifts-description {
          font-size: 14px;
        }
        .featured-subcategory {
          position: relative;
          width: 100%;
          margin: 5px 0;
          padding: 20px 20px 0 20px;
        }
        @media (min-width: ${theme.breakpoints.smDown}) {
          .gifts-title {
            font-size: 20px;
          }
          .gifts-description {
            font-size: 16px;
          }
          .featured-subcategory {
            margin: 10px 0 0 0;
          }
        }

        @media (min-width: ${theme.breakpoints.mdDown}) {
          .gifts-subcategory-container {
            margin: 20px 0;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
          }
        }
        @media (min-width: ${theme.breakpoints.xlDown}) {
          .gifts-title {
            font-size: 22px;
          }
          .gifts-description {
            font-size: 18px;
          }
        }
      `}</style>
    </>
  );
};

FeaturedSubcatsContainer.propTypes = {
  isGifts: PropTypes.bool,
  specifiedSubcats: PropTypes.array,
};

export default FeaturedSubcatsContainer;
