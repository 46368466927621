import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';
import {
  FilterButtonStyle,
  FilterButtonWrapper,
  FilterIconStyle,
} from './FilterButtonStyle';

const FilterButton = ({
  open,
  label,
  children,
  onClick,
  isMapPage = false,
}) => {
  const theme = useContext(ThemeContext);
  const container = useRef(null);

  // avoid errors because ssr returns mismatch html on mobile.
  const [ready, setready] = useState(false);
  useEffect(() => {
    setready(true);
  }, []);

  // handle click outside for non mobile
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!container.current || !container.current.contains(event.target)) {
        onClick();
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [open, onClick]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <div
        className={classNames({ 'maps-button-container': isMapPage })}
        ref={container}
        style={FilterButtonWrapper}
      >
        <button
          onClick={onClick}
          style={FilterButtonStyle({ open, theme })}
          type="button"
        >
          {label}
          <span style={FilterIconStyle({ open, theme })}>
            <FontAwesomeIcon
              icon={open ? faAngleUp : faAngleDown}
              /* eslint-disable-next-line react/forbid-component-props */
              style={{ fontSize: 24 }}
            />
          </span>
        </button>
        {open && <div className="card">{children}</div>}
      </div>
      <style jsx>{`
        .maps-button-container {
          min-width: 280px;
        }
        .card {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background-color: white;
          padding: 49px 0 0 0;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          border-radius: 4px;
          font-size: 19px;
          z-index: 1;
        }
      `}</style>
    </>
  );
};

FilterButton.propTypes = {
  children: PropTypes.any,
  isMapPage: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default FilterButton;
