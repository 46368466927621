import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../providers/ThemeProvider';

const MainDealTopMessageBanner = ({ message }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="deal-main-deal__redirected">{message}</div>
      <style jsx>{`
        .deal-main-deal__redirected {
          padding: 12px;
          color: ${theme.commoncolors.yellow};
          background-color: ${theme.commoncolors.lightyellow};
          border: 1px solid ${theme.commoncolors.yellow};
          margin: 0 0 16px;
          text-align: center;
          min-height: 46px;
        }
      `}</style>
    </>
  );
};

MainDealTopMessageBanner.propTypes = {
  message: PropTypes.string.isRequired,
};

export default MainDealTopMessageBanner;
