import startCase from 'lodash/startCase';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { hyperLocalSeo } from '../../helpers/hyperLocalSeo';

const HyperLocalFallbackMessage = () => {
  const [facetedNavigation, searchResult] = useSelector((state) => [
    state.deals.facetedNavigation,
    state.deals.searchResult,
  ]);
  const router = useRouter();
  const hyperLocalArea = router?.asPath.split('/').pop();

  if (!searchResult || searchResult === 1) return null;
  const { hyperLocalSeoTitle = '' } = hyperLocalSeo(router, facetedNavigation);
  const fallbackCategory = facetedNavigation?.defaultItem.categoryName;
  const HYPER_LOCAL_FALLBACK_MESSAGE_ONE = `Sorry, there are no deals for <span class="highligter">${hyperLocalSeoTitle}</span> right now, here are some similar deals further afield.`;
  const HYPER_LOCAL_FALLBACK_MESSAGE_TWO = `Sorry there are no relevant deals near ${startCase(
    hyperLocalArea,
  )} right now, here are deals for all ${fallbackCategory} instead.`;
  const newHyperLocalFallbackMessageOne = (
    <span
      dangerouslySetInnerHTML={{ __html: HYPER_LOCAL_FALLBACK_MESSAGE_ONE }}
    />
  );

  return (
    <>
      <div className="hyper-local-fallback">
        <h3>
          {
            {
              1: null,
              2: newHyperLocalFallbackMessageOne,
              3: HYPER_LOCAL_FALLBACK_MESSAGE_TWO,
            }[searchResult]
          }
        </h3>
      </div>

      <style jsx>{`
        .hyper-local-fallback {
          margin-bottom: 1em;
          text-align: center;
          width: 100%;
          background: #f0f0f0;
          padding: 0.5em;
          font-size: 1.2em;
          font-family: 'Open sans', sans-serif;
        }
        :global(.highligter) {
          color: #5a266a;
        }

        .hyper-local-fallback h3 {
          background: white;
          border-radius: 10px;
          padding: 1em;
          margin: 0;
        }
      `}</style>
    </>
  );
};

export default HyperLocalFallbackMessage;
