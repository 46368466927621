import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SAVE, SAVE_UP_TO } from '../../config/text/text';
import { getColumn } from '../../helpers/chooseYourOptions';
import { formatCurrency } from '../../helpers/currency';
import { getIsChooseYourProductDeal } from '../../helpers/deals';
import { updateProductPageDetails } from '../../helpers/productPage';
import ThemeContext from '../../providers/ThemeProvider';

const MainDealDiscount = ({
  deal,
  isProductPage,
  position,
  rowSelection,
  columnSelection,
}) => {
  const theme = useContext(ThemeContext);
  const isVipUser = useSelector((state) => state?.user?.userprofile?.vipUser);
  const column = getColumn(deal, rowSelection, isVipUser, isProductPage);
  const isChooseYourProductDeal = getIsChooseYourProductDeal(deal);
  let discount = deal.discount;
  let discountPercentage = deal?.discountPercentage;
  let vipDiscountPercentage = deal?.vipDiscountPercentage;
  if (
    isProductPage &&
    position >= 0 &&
    updateProductPageDetails(column, isChooseYourProductDeal, columnSelection)
  ) {
    const filteredProduct = deal?.products[position];
    discount = filteredProduct?.discount;
    discountPercentage = filteredProduct?.discountPercentage;
    vipDiscountPercentage = filteredProduct?.discountPercentage;
  }

  if (!deal?.display) {
    return null;
  }

  return (
    <>
      {(deal.display.discountAmount && deal.discount > 0 && (
        <div className="deal-main-deal__discount">
          <span className="deal-main-deal__discount-text">
            {deal.priceIndicative ? SAVE_UP_TO : SAVE}
          </span>{' '}
          <span className="deal-main-deal__discount-number">
            {formatCurrency(deal.currency, discount, false, false)}!
          </span>
        </div>
      )) ||
        (deal.display.discount && deal.discountPercentage > 0 && (
          <div className="deal-main-deal__discount">
            <span className="deal-main-deal__discount-text">
              {deal.priceIndicative ? SAVE_UP_TO : SAVE}
            </span>{' '}
            <span className="deal-main-deal__discount-number">
              {isVipUser && deal?.vipDiscountPercentage
                ? vipDiscountPercentage
                : discountPercentage}
              %!
            </span>
          </div>
        ))}

      <style jsx>{`
        .deal-main-deal__discount {
          text-align: center;
          color: #ffffff;
          background-color: #444444;
          padding: 8px;
          margin-left: 16px;
          font-weight: bold;
          text-align: center;
        }
        .deal-main-deal__discount-text {
          font-size: 14px;
          text-transform: uppercase;
          vertical-align: middle;
          line-height: 1em;
        }
        .deal-main-deal__discount-number {
          font-size: 22px;
          vertical-align: middle;
          line-height: 1em;
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .deal-main-deal__discount {
            height: 80px;
            width: 80px;
            min-width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-top: 0;
          }
          .deal-main-deal__discount-text,
          .deal-main-deal__discount-number {
            width: 60px;
          }
        }

        @media (min-width: ${theme.breakpoints.mdUp}) {
          :global(.main-deal-travel
              .deal-main-deal__discount
              .deal-main-deal__discount-text) {
            font-size: 20px;
          }

          :global(.main-deal-travel .deal-main-deal__discount-number) {
            font-size: 30px;
          }

          :global(.main-deal-travel .deal-main-deal__discount) {
            margin-left: 0px;
          }
        }
      `}</style>
    </>
  );
};

MainDealDiscount.propTypes = {
  deal: PropTypes.object,
  isProductPage: PropTypes.bool,
  position: PropTypes.number,
};

MainDealDiscount.defaultProps = {
  deal: null,
};

export default MainDealDiscount;
