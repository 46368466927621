import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterPopularity,
  filterPrice,
  filterPriceTravel,
  FILTER_ALL,
  FILTER_MAXPRICE,
  FILTER_MINPRICE,
  FILTER_SORTBY,
  FILTER_SUBCATEGORY,
} from '../../config/setup/setup';
import { FILTER_SORT_BY, FILTER_PRICE } from '../../config/text/text';
import { getCountryCurrency } from '../../helpers/currency';
import {
  getNavigationCategoriesWithFaceted,
  assignSliderValue,
  getUrlWithFilterQuery,
  isFiltered,
} from '../../helpers/filters';
import { parseWowcherPath } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import { setURLFilterPath } from '../../redux/actions/filters';
import FilterRange from '../_generic/filter/FilterRange';
import FilterSelect from '../_generic/filter/FilterSelect';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Filters = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const currency = getCountryCurrency();
  const facetedNavigation = useSelector(
    (state) => state.deals?.facetedNavigation,
  );
  const deals = useSelector((state) => state.deals?.deals || []);
  const filterNavigation = getNavigationCategoriesWithFaceted(
    facetedNavigation,
  );
  const { searchParams, details } = parseWowcherPath(router.asPath);
  const hyperLocalArea = router?.asPath.split('/').pop();
  const marks = details.isTravel ? filterPriceTravel : filterPrice;

  useEffect(() => {
    if (details.isHyperlocal || !isFiltered()) {
      dispatch(setURLFilterPath(''));

      return;
    }

    let filterSubCat;
    if (searchParams.has(FILTER_SUBCATEGORY)) {
      const value = searchParams.get(FILTER_SUBCATEGORY);
      filterSubCat = value === 'all' ? undefined : value;
    } else {
      filterSubCat = details.subCategory;
    }

    const filterPath = [
      'deals',
      details.localDealLocation || 'shop',
      details.category,
      filterSubCat,
    ]
      .filter((value) => value)
      .join('/');
    dispatch(setURLFilterPath(filterPath));
  }, [router.query, details, dispatch, searchParams]);

  let subCat;
  if (searchParams.has(FILTER_SUBCATEGORY)) {
    subCat = searchParams.get(FILTER_SUBCATEGORY);
  } else if (details.isHyperlocal && details.subCategory === hyperLocalArea) {
    subCat = FILTER_ALL;
  } else {
    subCat = details.subCategory ?? FILTER_ALL;
  }

  const sortBy = searchParams.get(FILTER_SORTBY) ?? '-1';

  const sliderValues = [
    assignSliderValue(searchParams?.get(FILTER_MINPRICE) ?? null, marks, 0),
    assignSliderValue(
      searchParams?.get(FILTER_MAXPRICE) ?? null,
      marks,
      marks.length - 1,
    ),
  ];

  const updateFilter = debounce((config) => {
    const url = getUrlWithFilterQuery({
      details,
      filterPrice: marks,
      sliderValues: config.sliderValues,
      sortBy: config.sortBy,
      subCat: config.subCat,
    });
    router.replace(url, url, { shallow: true });
  }, 500);

  if (!filterNavigation || filterNavigation.length <= 1 || deals.length === 0) {
    return null;
  }

  return (
    <>
      <div className="full-row background-primary">
        <div className="container">
          <div className="filters" name="filter">
            <div
              className={classNames('filter-item filter-item--large', {
                hiddenBlock: !filterNavigation || !filterNavigation.length,
              })}
              id="filter"
            >
              <FilterSelect
                onChange={(value) => {
                  updateFilter({ sliderValues, sortBy, subCat: value });
                }}
                options={filterNavigation}
                selected={subCat}
              />
            </div>

            <div className="filter-item">
              <FilterSelect
                label={FILTER_SORT_BY}
                onChange={(value) => {
                  updateFilter({ sliderValues, sortBy: value, subCat });
                }}
                options={filterPopularity}
                selected={sortBy}
              />
            </div>

            <div className="filter-item">
              <FilterRange
                label={FILTER_PRICE}
                marks={marks.reduce((out, item, index) => {
                  out[index] = {
                    label: '##currency####price##'
                      .replace('##currency##', currency)
                      .replace('##price##', item.name),
                  };
                  if (index === marks.length - 1) {
                    out[index].label += '+';
                  }

                  return out;
                }, {})}
                max={marks.length - 1}
                min={0}
                onChange={(value) => {
                  updateFilter({ sliderValues: value, sortBy, subCat });
                }}
                onReset={() => {
                  updateFilter({
                    sliderValues: [0, marks.length - 1],
                    sortBy,
                    subCat,
                  });
                }}
                value={sliderValues}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .background-primary {
          background-color: ${theme.colors.primary};
          position: relative;
          z-index: 999;
          margin-bottom: 5px;
        }
        .filters {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          width: 100%;
        }
        .filter-item {
          width: 240px;
          margin-right: 10px;
        }
        .filter-item:first-child {
          display: none;
        }
        .filter-item:last-child {
          margin-right: 0;
        }
        .filter-item--large {
          width: 310px;
        }
        .hiddenBlock {
          display: none;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .background-primary {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .filters {
            padding: 10px 0;
          }
          .filter-item:first-child {
            display: block;
          }
          .filter-item--large {
            width: 340px;
          }
        }
        @media (min-width: ${theme.breakpoints.xlUp}) {
          .filters {
            justify-content: flex-start;
          }
        }
      `}</style>
    </>
  );
};

export default Filters;
