/* eslint-disable sonarjs/cognitive-complexity */
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { MARKETING_CLAIMS_DEF_LINK } from '../../../config/links/links';
import {
  BOUGHT_COUNT_CUE,
  FEATURE_FLAGS,
  SOCIAL_CUES,
} from '../../../config/setup/setup';
import {
  SAVE,
  SAVE_UP_TO,
  SOCIAL_CUE_TOTAL_BOUGTH,
  SOCIAL_CUE_TOTAL_REMAINING,
} from '../../../config/text/text';
import { formatCurrency } from '../../../helpers/currency';
import { isPrice } from '../../../helpers/deals';
import { isIESite } from '../../../helpers/pages';
import {
  getBackendSocialCueMessage,
  getRedTextDealSocialCue,
} from '../../../helpers/socialCues';
import ThemeContext from '../../../providers/ThemeProvider';
import PostageAndPackagingText from './PostageAndPackagingText';

const DealSocialCuesBottom = ({
  socialCuesData,
  socialCuesExtra,
  hideBoughtCount,
  hideCue,
  children,
  discount,
  forcePrice,
  dealTitle,
  deal,
  show,
}) => {
  const theme = useContext(ThemeContext);
  if (
    !socialCuesData ||
    (!socialCuesData.totalRemaining && !socialCuesData.totalBought)
  )
    return null;

  let socialCueMessageExtra = '';
  if (socialCuesExtra && Object.keys(socialCuesExtra).length !== 0) {
    socialCueMessageExtra = getBackendSocialCueMessage(socialCuesExtra);
  }

  const socialCueMessage = getRedTextDealSocialCue({ deal: socialCuesData });

  const dealData = {
    discount: socialCuesData.display?.discountAmount
      ? formatCurrency(socialCuesData.currency, socialCuesData.discount)
      : `${Math.round(socialCuesData?.discountPercentage)}%`,
    price: socialCuesData?.originalPrice || null,
  };

  const isIrelandSite = isIESite();

  const showDiscount =
    socialCuesData?.display?.discount &&
    isPrice({ deal: socialCuesData, forcePrice, showDiscount: discount });

  const postageAndPackagingComponent = () => {
    if (dealTitle && !isMobile && deal.minPostagePrice !== null) {
      return (
        <PostageAndPackagingText
          bigTile
          deal={deal}
          discount={discount}
          show={show}
        />
      );
    } else return null;
  };

  const getBoughtCount = () => {
    if (
      !socialCuesData ||
      !socialCuesData.display ||
      !socialCuesData.display.bought
    ) {
      return null;
    }
    if (socialCuesData.totalBought === 0) {
      if (socialCuesData.newDealMessageTag) {
        return (
          <div className="social-cue__item bought-count">
            {socialCuesData.newDealMessageTag}
          </div>
        );
      }

      return null;
    }

    return (
      <span
        className={classNames({
          'social-cue__item bought-count':
            FEATURE_FLAGS[BOUGHT_COUNT_CUE] || isMobile, // Logic Change Due to Disabled Bought Count
        })}
      >
        {FEATURE_FLAGS[BOUGHT_COUNT_CUE] && (
          <span className="total-bought">
            {SOCIAL_CUE_TOTAL_BOUGTH.replace(
              '##COUNT##',
              socialCuesData.totalBought,
            )}
          </span>
        )}
        {showDiscount && isMobile && (
          <span className="total-discount">
            <span className="save-price">
              {`${socialCuesData.priceIndicative ? SAVE_UP_TO : SAVE} ${
                dealData.discount
              }`}
            </span>
            <span className="originalPrice strikethrough">
              {formatCurrency(
                socialCuesData.currency,
                dealData.price,
                false,
                false,
              )}
            </span>
          </span>
        )}
      </span>
    );
  };

  const getBoughtRemaining = () => {
    if (
      !socialCuesData ||
      !socialCuesData.display ||
      !socialCuesData.display.quantityRemaining ||
      !socialCuesData.totalRemaining
    )
      return null;

    return (
      <div className="social-cue__item totalRemaining bought-count">
        {SOCIAL_CUE_TOTAL_REMAINING.replace(
          '##COUNT##',
          socialCuesData.totalRemaining,
        )}
      </div>
    );
  };

  const getSocialCue = () => {
    if (!FEATURE_FLAGS[SOCIAL_CUES]) {
      return null;
    }
    if (
      !socialCueMessageExtra ||
      !socialCueMessageExtra.length ||
      !socialCueMessage ||
      !socialCueMessage.length
    )
      return null;

    return (
      <div className="social-cue__item availability">
        <div className="social-text">{socialCueMessage}</div>
        {!isIrelandSite && (
          <FontAwesomeIcon
            icon={faInfoCircle}
            onClick={(e) => {
              e.stopPropagation();
              window.open(MARKETING_CLAIMS_DEF_LINK, '_blank');
            }}
            style={{
              cursor: 'pointer',
              marginLeft: '0.2rem',
            }}
          />
        )}
      </div>
    );
  };

  const getChildren = () => {
    return (
      <>
        {React.Children.map(children, (child) => {
          const mobileClassCheck =
            child.props.className.includes('hide-mobile') && isMobile;
          if (child.props.show && !mobileClassCheck)
            return <div className="social-cue__item-child">{child}</div>;
        })}
      </>
    );
  };

  return (
    <>
      <div className="social-cues-container__main-image__bottom">
        {!hideCue && getSocialCue()}
        {!hideBoughtCount &&
          (socialCuesData &&
          socialCuesData.display &&
          socialCuesData.display.quantityRemaining
            ? getBoughtRemaining()
            : getBoughtCount())}
        {getChildren()}
        {postageAndPackagingComponent()}
      </div>

      <style global jsx>{`
        .social-cue__item,
        .social-cue__item-child {
          z-index: 999;
        }
        .social-cues-container__main-image__bottom {
          display:flex;
          width: 100%;
          position: absolute;
          bottom: 0;
          padding-left: 10px;
          pointer-events: auto;
          z-index: 999;
        }
        .splat + .social-cues-container__main-image__bottom {
          width: calc(100% - 85px);
        }

        .total-bought {
          margin-right: 10px;
        }

        .total-bought:last-child {
          margin-right: 0;
        }

        .social-cues-container__main-image__bottom .social-cue__item,
        .social-cues-container__main-image__bottom .social-cue__item-child {
          width: max-content;
          margin: 0 8px 10px 0;
          padding: 0px 10px;
          background-color: ${theme.colors.primary};
          color: ${theme.colors.textonprimary};
          font-weight: bold;
          border-radius: 20px;
          padding-top: 2px;
          padding-bottom: 2px;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 24px;
          vertical-align: middle;
        }
        .social-cues-container__main-image__bottom
          .social-cue__item.bought-count {
          background-color: rgba(243, 243, 243, 0.9);
          border: 0;
          color: ${theme.colors.dealboughtcounttext};
          font-weight: normal;
          padding: 1px 10px;
          width: 100%;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .social-cues-container__main-image__bottom
            .social-cue__item.bought-count {
            border: 1px solid ${theme.colors.dealboughtcounttext};
            width: auto;
          }
        }
        .social-cues-container__main-image__bottom .social-cue__item-child {
          background-color: rgba(243, 243, 243, 0.6);
          color: ${theme.colors.dealboughtcounttext};
          font-weight: normal;
          padding: 1px 3px;
          height: 17px;
        }

        .social-cues-container__main-image__bottom
          .social-cue__item.availability {
          background-color: ${theme.colors.socialcuecolor.primary};
          color: ${theme.colors.socialcuecolor.textonprimary};
        }

        .social-text,
        .social-icon {
          display: inline-block;
        }

        .originalPrice,
        .save-price {
          display: inline-block;
          position: relative;
        }

        .originalPrice {
          margin-left: 10px;
        }

        .strikethrough:before {
          position: absolute;
          content: '';
          left -4px;
          top: 50%;
          right: 0;
          width: calc(100% + 8px);
          border-top: 1px solid;
          transform: rotate(-11deg);
        }
        
      `}</style>
    </>
  );
};

DealSocialCuesBottom.propTypes = {
  children: PropTypes.node,
  deal: PropTypes.object,
  discount: PropTypes.bool,
  forcePrice: PropTypes.bool,
  hideBoughtCount: PropTypes.bool,
  hideCue: PropTypes.bool,
  socialCuesData: PropTypes.object,
  socialCuesExtra: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

DealSocialCuesBottom.defaultProps = {
  children: null,
  hideBoughtCount: false,
  hideCue: false,
  newDealMessageTag: '',
  socialCuesData: {},
  socialCuesExtra: {},
};

export default DealSocialCuesBottom;
