/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  DEFAULT_DEAL_IMG_HEIGHT,
  DEFAULT_DEAL_IMG_WIDTH,
  DEFAULT_DEAL_IMG_HEIGHT_MOBILE,
  DEFAULT_DEAL_IMG_WIDTH_MOBILE,
  DEFAULT_DEAL_IMG_HEIGHT_MOBILE_LG,
  DEFAULT_DEAL_IMG_WIDTH_MOBILE_LG,
} from '../../../../config/setup/setup';
import {
  replaceImageServer,
  usePlaceholderImage,
} from '../../../../helpers/image';
import ThemeContext from '../../../../providers/ThemeProvider';
import WrapLink from '../../../_generic/utils/WrapLink';
import ImageOverlays from '../ImageOverlays';
import DealMobileScrim from '../dealMobileScrim';

const ImageComponent = ({
  deal = {},
  height = DEFAULT_DEAL_IMG_HEIGHT,
  image = {},
  index = 0,
  isLeadGen = false,
  secondCheckout = false,
  showImageOverlays = false,
  showScrim = true,
  width = DEFAULT_DEAL_IMG_WIDTH,
  wrapLinkHref = null,
  imageVariant,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <WrapLink href={wrapLinkHref} isExternal={isLeadGen}>
      <picture className="single-image__picture">
        <source
          height={DEFAULT_DEAL_IMG_HEIGHT_MOBILE}
          media={`(max-width: ${DEFAULT_DEAL_IMG_WIDTH_MOBILE}px)`}
          srcSet={`${replaceImageServer({
            dealId: deal.id,
            height: DEFAULT_DEAL_IMG_HEIGHT_MOBILE,
            image: imageVariant ? imageVariant : image,
            width: DEFAULT_DEAL_IMG_WIDTH_MOBILE,
          })}`}
          width={DEFAULT_DEAL_IMG_WIDTH_MOBILE}
        />
        <source
          height={DEFAULT_DEAL_IMG_HEIGHT_MOBILE_LG}
          media={`(max-width: ${theme.breakpoints.smDown})`}
          srcSet={`${replaceImageServer({
            dealId: deal.id,
            height: DEFAULT_DEAL_IMG_HEIGHT_MOBILE_LG,
            image: imageVariant ? imageVariant : image,
            width: DEFAULT_DEAL_IMG_WIDTH_MOBILE_LG,
          })}`}
          width={DEFAULT_DEAL_IMG_WIDTH_MOBILE_LG}
        />
        <img
          alt={image.alt}
          as="image"
          async={index !== 0}
          className="single-image__image"
          defer={index !== 0}
          fetchpriority={index === 0 ? 'high' : 'low'}
          height={height}
          loading={index === 0 ? 'eager' : 'lazy'}
          onError={usePlaceholderImage}
          rel={index === 0 ? 'preload' : ''}
          src={replaceImageServer({
            dealId: deal.id,
            height,
            image: imageVariant ? imageVariant : image,
            width,
          })}
          width={width}
        />
      </picture>
      <ImageOverlays image={image} show={showImageOverlays} />
      {showScrim && <DealMobileScrim secondCheckout={secondCheckout} />}
    </WrapLink>
  );
};

ImageComponent.propTypes = {
  deal: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.object,
  imageVariant: PropTypes.string,
  index: PropTypes.number,
  isLeadGen: PropTypes.bool,
  secondCheckout: PropTypes.bool,
  showImageOverlays: PropTypes.bool,
  showScrim: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapLinkHref: PropTypes.string,
};

export default ImageComponent;
