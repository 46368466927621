/* eslint-disable react-hooks/exhaustive-deps, react/forbid-component-props, sonarjs/cognitive-complexity, no-param-reassign */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  DEFAULT_DEAL_IMG_HEIGHT,
  DEFAULT_DEAL_IMG_WIDTH,
  intervalTime,
  transitionTime,
} from '../../../../config/setup/setup';
import { updateDod } from '../../../../helpers/analytics';
import { getUrlFromDeal } from '../../../../helpers/deals';
import { showVideo } from '../../../../helpers/video';
import ThemeContext from '../../../../providers/ThemeProvider';
import CarouselImageBlock from '../carousel-common/CarouselImageBlock';
import CarouselVideo from '../carousel-common/CarouselVideo';
import CarouselThumbs from './CarouselThumbs';

const CarouselArrow = dynamic(
  () => import('../carousel-common/CarouselArrow'),
  {
    ssr: false,
  },
);

const CarouselBlock = ({
  carouselType = 'horizontalScroller',
  deal = {},
  disableClickAction = false,
  height = DEFAULT_DEAL_IMG_HEIGHT,
  href = null,
  invert = false,
  isClosed = false,
  lazy = true,
  mapView = false,
  onClick,
  secondCheckout,
  showControls = false,
  showImageOverlays = false,
  imageVariantIndex,
  showScrim = false,
  showSingle = false,
  single = false,
  imageVariant,
  thumbCount,
  thumbWidth = 54,
  isProductPage,
  width = DEFAULT_DEAL_IMG_WIDTH,
}) => {
  const theme = useContext(ThemeContext);
  const [currentImage, setCurrentImage] = useState(
    imageVariantIndex ? imageVariantIndex : 0,
  );
  const hasVideo = showVideo(deal);
  const router = useRouter();
  const first = deal.images ? deal.images[0] : {};
  const dealUrl =
    href ||
    getUrlFromDeal({
      deal,
      originPath: router.asPath,
    });

  useEffect(() => {
    carouselType = `${carouselType}position`;
    updateDod({ carouselType: currentImage });
  }, [currentImage]);

  useEffect(() => {
    if (isProductPage) {
      setCurrentImage(imageVariantIndex);
    }
  }, [imageVariantIndex]);
  if (Object.keys(deal).length === 0) return null;

  const renderCarouselWrapper = (items) => (
    <>
      <Carousel
        autoPlay={false}
        className="wow-carousel"
        emulateTouch
        infiniteLoop
        interval={intervalTime}
        onChange={(index) => setCurrentImage(index)}
        renderArrowNext={(onClickHandler) => (
          <CarouselArrow onClickHandler={onClickHandler} orientation="next" />
        )}
        renderArrowPrev={(onClickHandler) => (
          <CarouselArrow onClickHandler={onClickHandler} orientation="prev" />
        )}
        renderIndicator={(_, isSelected) => (
          <div
            className={`dot ${isSelected ? 'selected' : ''}`}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        selectedItem={currentImage}
        showIndicators={!hasVideo}
        showStatus={false}
        showThumbs={false}
        swipeable
      >
        {items}
      </Carousel>
      <CarouselThumbs
        deal={deal}
        invert={invert}
        onSelect={(index) => setCurrentImage(index)}
        selection={currentImage}
        thumbCount={thumbCount}
        thumbWidth={thumbWidth}
        transitionTime={transitionTime}
      />
      <style global jsx>{`
        .wow-carousel .carousel .control-dots .dot {
          opacity: 1;
          box-shadow: none;
          border: 2px solid ${theme.colors.primary};
          margin: 0 3px;
          cursor: default;
        }
        .wow-carousel .dot.selected {
          background-color: ${theme.colors.primary};
        }
        @media (prefers-reduced-motion) {
          .wow-carousel .slider {
            transition: none;
          }
        }

        @media (max-width: ${theme.breakpoints.smDown}) {
          .wow-carousel .carousel .control-dots {
            bottom: -10px;
          }
        }
      `}</style>
    </>
  );

  const renderImages = (items) =>
    items.map((image, index) =>
      index === 0 && hasVideo ? (
        <CarouselVideo
          deal={deal}
          href={mapView ? '' : dealUrl}
          isClosed={isClosed}
          key={image.id || index}
          onClick={onClick}
          showControls={showControls}
          showScrim={showScrim}
        />
      ) : (
        <CarouselImageBlock
          currentImage={currentImage}
          deal={deal}
          dealUrl={mapView ? '' : dealUrl}
          disableClickAction={disableClickAction}
          height={height}
          image={image}
          imageVariant={imageVariant}
          imageVariantIndex={imageVariantIndex}
          index={index}
          invert={invert}
          items={items}
          key={image.id || index}
          lazy={lazy}
          mapView={mapView}
          onClick={onClick}
          secondCheckout={secondCheckout}
          setCurrentImage={setCurrentImage}
          showImageOverlays={showImageOverlays}
          showScrim={showScrim}
          showSingle={showSingle}
          thumbCount={thumbCount}
          thumbWidth={thumbWidth}
          width={width}
        />
      ),
    );

  if (!deal.images || deal.images.length === 0) {
    // force the fallback image to render
    return renderImages([{}]);
  }

  return deal.images?.length > 1 && !single
    ? renderCarouselWrapper(renderImages(deal.images))
    : renderImages([first]);
};

CarouselBlock.propTypes = {
  deal: PropTypes.object,
  disableClickAction: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  href: PropTypes.string,
  imageVariantIndex: PropTypes.number,
  invert: PropTypes.bool,
  isClosed: PropTypes.bool,
  lazy: PropTypes.bool,
  mapView: PropTypes.bool,
  onClick: PropTypes.func,
  secondCheckout: PropTypes.bool,
  showControls: PropTypes.bool,
  showImageOverlays: PropTypes.bool,
  showScrim: PropTypes.bool,
  showSingle: PropTypes.bool,
  single: PropTypes.bool,
  thumbCount: PropTypes.number,
  thumbWidth: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CarouselBlock;
