/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'react-cookies';
import { REFEREE_WELCOME_BANNER } from '../../config/constants/images';
import COOKIES from '../../config/cookies/cookies';
import {
  CREDIT_ADDED_TEXT,
  START_SHOPPING,
  TO_THE_CLUB,
  WELCOME,
} from '../../config/text/text';
import { trackEvent } from '../../helpers/analytics';
import { removeCookie } from '../../helpers/cookieSetter';
import ThemeContext from '../../providers/ThemeProvider';
import WowModal from '../WowModal';
import PrimaryButton from '../_generic/button/PrimaryButton';

const RefereeWelcomeBanner = ({ referralCredit }) => {
  const theme = useContext(ThemeContext);
  const [isRefereeWelcomeBanner, setIsRefereeWelcomeBanner] = useState(true);

  useEffect(() => {
    setIsRefereeWelcomeBanner(Cookies.load(COOKIES.referralRegistered));
  }, []);

  return (
    <>
      <WowModal
        ariaLabelledBy="referee-welcome-club"
        centered
        extraClasses={'referee-welcome-modal no-body-padding'}
        setShowModal={(value) => {
          setIsRefereeWelcomeBanner(value);
          removeCookie(COOKIES.referralRegistered);
        }}
        showCloseButton={false}
        showHeader={false}
        showModal={isRefereeWelcomeBanner}
        size={'md'}
      >
        <div className="welcome-container">
          <div className="welcome-content__right">
            <div className="welcome-content__heading">
              <div className="text--lg">{WELCOME}</div>
              <div className="text--md">{TO_THE_CLUB}</div>
            </div>
            <>
              <span className="text--sm">
                {CREDIT_ADDED_TEXT(referralCredit || '10')}
              </span>
            </>{' '}
            <PrimaryButton
              className="fit-content size-medium"
              onClick={() => {
                setIsRefereeWelcomeBanner(false);
                trackEvent('raf: start shopping click');
                removeCookie(COOKIES.referralRegistered);
              }}
            >
              {START_SHOPPING}
            </PrimaryButton>
          </div>
        </div>
      </WowModal>
      <style jsx>{`
        .welcome-container {
          font-size: 16px;
          font-weight: 200;
          background: url(${REFEREE_WELCOME_BANNER});
          background-size: cover;
          text-align: center;
        }
        .welcome-content__right {
          width: 55%;
          height: 100%;
          padding: 1.5rem 0.6rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          margin-left: auto;
          text-transform: uppercase;
        }
        .text--lg {
          font-size: 32px;
          line-height: 30px;
          font-weight: 800;
        }
        .text--md {
          font-size: 16px;
          font-weight: 500;
        }
        .text--sm {
          font-size: 10px;
        }
        .banner--text {
          font-size: 20px;
          max-width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .banner--text > span {
          line-height: 22px;
        }

        .text--bold {
          font-weight: 600;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .welcome-content__right {
            width: 55%;
            padding: 3rem 0.5rem;
            gap: 1rem;
          }
          .text--lg {
            font-size: 42px;
            line-height: 40px;
            font-weight: 800;
          }
          .text--md {
            font-size: 20px;
            font-weight: 500;
          }
          .text--sm {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );
};

RefereeWelcomeBanner.propTypes = {
  referralCredit: PropTypes.string,
};

export default RefereeWelcomeBanner;
