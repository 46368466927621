/* eslint-disable sonarjs/cognitive-complexity, complexity, @next/next/no-img-element, react/no-array-index-key, prefer-const */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSWRInfinite } from 'swr';
import { TRACKING } from '../../../config/constants/action-types';
import { ORDER } from '../../../config/constants/common';
import { VIP_BG } from '../../../config/constants/images';
import { VIP_QUALIFIERS } from '../../../config/constants/page-types';
import { DESKTOP_PAGE_SIZE } from '../../../config/links/links';
import {
  MAX_CATEGORY_DEALS_BLOCK,
  SITE_FIVEPM,
} from '../../../config/setup/setup';
import { cutArray } from '../../../helpers/deals';
import { isWowcherIE } from '../../../helpers/pages';
import { isSSR } from '../../../helpers/ssr';
import { parseWowcherPath } from '../../../helpers/url';
import useClientSideEffect from '../../../helpers/useClientSideEffect';
import useRestoreScroll from '../../../helpers/useRestoreScroll';
import { useVideoScroll } from '../../../helpers/video';
import ThemeContext from '../../../providers/ThemeProvider';
import AdditionalContent from '../../AdditionalContent';
import VipContact from '../../VipContact';
import TwoByTwoPlaceholder from '../../_generic/loading-placeholder/TwoByTwoPlaceholder';
import Spinner from '../../_generic/spinner/Spinner';
import MerchandisingModule from '../../category/MerchandisingModule';
import PopularLocationsList from '../../popular-locations-list/PopularLocationsList';
import TwoByTwoDealCard from '../TwoByTwoDealCard';
import BlockWithOneFeaturedDeal from '../shared-components/blockWithOneFeaturedDeal';
import EndOfFeed from './EndOfFeed';
import LoadMore from './LoadMore';
import LoadPrevious from './LoadPrevious';
import { DEAL_FOCUS_RETRIES } from './helpers';

export const LAYOUT_TYPES = {
  claimTwoByTwo: 'claim-2x2',
  grid: 'grid',
  twoByTwo: '2x2',
};

export const template = ({
  type,
  deals,
  path,
  popularLocationsData,
  theme,
  isVipHubPage,
  isEarlyBird,
  additionalContentText,
  isWowcherIe = false,
}) => {
  switch (type) {
    case LAYOUT_TYPES.claimTwoByTwo:
    case LAYOUT_TYPES.twoByTwo: {
      const flatDeals = deals.flat().map((deal, index) => {
        return { ...deal, order: index % 2 === 0 ? ORDER.even : ORDER.odd };
      });
      const sectionedDeals = cutArray(flatDeals, 8);

      return sectionedDeals.map((page, index) => {
        return (
          <Fragment key={index}>
            <div className="main-container-static">
              {isEarlyBird && (
                <img
                  alt="background-black"
                  className="bg-container"
                  src={VIP_BG}
                />
              )}
              <div className="page bottom-deals container deals-products-list deal-block">
                {page.map((deal) => {
                  const isClaimLayout = type === LAYOUT_TYPES.claimTwoByTwo;
                  if (isClaimLayout) deal.isClaim = true;

                  return (
                    <TwoByTwoDealCard
                      deal={deal}
                      isHideSplat={isClaimLayout}
                      // sponsored deals can be repeated in the feed so make sure keys are different
                      key={`${deal.id}${deal.isSponsored && '-sponsored'}`}
                    />
                  );
                })}
              </div>
              <style jsx>{`
                .page.container {
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: stretch;
                  padding-top: 20px;
                  margin-bottom: -20px;
                }

                .bg-container {
                  position: fixed;
                  height: 100vh;
                  width: 100vw;
                  object-fit: cover;
                  z-index: -1;
                  margin-top: -14rem;
                }
                @media (max-width: ${theme.breakpoints.smUp}) {
                  .main-container-static {
                  }
                }
                @media (max-width: ${theme.breakpoints.smDown}) {
                  .page.container {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding: 0px;
                  }
                }
              `}</style>
              {/* Merchandise Row */}
              <MerchandisingModule path={path} position={index + 1} />
              {index === 0 && additionalContentText && (
                <AdditionalContent
                  additionalContentText={additionalContentText}
                />
              )}
            </div>
          </Fragment>
        );
      });
    }
    case LAYOUT_TYPES.grid:
    default: {
      const flatDeals = deals.flat().map((deal, index) => {
        return { ...deal, order: index % 2 === 0 ? ORDER.even : ORDER.odd };
      });
      const sectionedDeals = cutArray(flatDeals, MAX_CATEGORY_DEALS_BLOCK);

      return sectionedDeals.map((page, index) => {
        return (
          <Fragment key={index}>
            <div className="bottom-deals container deals-products-list deal-block">
              <BlockWithOneFeaturedDeal deals={page} position={index} />
              {index === 0 &&
                !isWowcherIe &&
                process.env.NEXT_PUBLIC_SITE !== SITE_FIVEPM && (
                  <PopularLocationsList
                    popularLocationsData={popularLocationsData}
                  />
                )}
            </div>

            {index === 0 && path === `/deals/vip` && <VipContact key={index} />}
            {/* Merchandise Row */}
            {!isVipHubPage && (
              <MerchandisingModule path={path} position={index + 1} />
            )}
            {index === 0 && additionalContentText && (
              <AdditionalContent
                additionalContentText={additionalContentText}
              />
            )}
          </Fragment>
        );
      });
    }
  }
};

export const placeholderTemplate = (templateType) => {
  switch (templateType) {
    case LAYOUT_TYPES.twoByTwo: {
      return (
        <>
          <TwoByTwoPlaceholder />
          <TwoByTwoPlaceholder />
        </>
      );
    }
    case LAYOUT_TYPES.grid:
    default: {
      return (
        <>
          <Spinner display="relative" msgText="Loading..." />
        </>
      );
    }
  }
};

const ConnectedBottomDeals = ({
  additionalContentText,
  emptyText = '',
  fetcherFunction,
  firstPageSize,
  getKeyGenerator,
  isGuidepage,
  isSearchPage = false,
  numberSecondaryDeals,
  pageSize = DESKTOP_PAGE_SIZE,
  popularLocationsData = [],
  sponsoredSearchDeals = [],
  templateType,
  totalDeals,
}) => {
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const scrollRetriesRef = useRef(0);
  const [restoreScrollData, setRestoreScrollData] = useRestoreScroll();
  const [canRemoveSecondary, setCanRemoveSecondary] = useState(true);
  const [startPageNr, setStartPageNr] = useState(
    !isSSR() && restoreScrollData?.url === window.location.href
      ? restoreScrollData?.page
      : 0,
  );
  const getKeyRef = useRef(getKeyGenerator(startPageNr));
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const isWowcherIe = isWowcherIE();
  const path = router.asPath;
  const pathUrl = parseWowcherPath(path);
  const isVipHubPage = VIP_QUALIFIERS.includes(pathUrl.pageType);

  let { data: deals, error, setSize, isValidating, size } = useSWRInfinite(
    getKeyRef.current,
    fetcherFunction,
    {
      dedupingInterval: 600_000,
      // Load 1 on initial page load to reduce the number of assets to load
      // Load 2 on page back navigation, to prevent triggering load more
      // initialSize: startPageNr === 0 ? 1 : 2, - doesn't seem necessary now
      initialSize: 1,
      revalidateOnFocus: false,
    },
  );

  if (sponsoredSearchDeals.length > 0 && deals?.length > 0) {
    deals = [...sponsoredSearchDeals, ...deals];
  }

  // These MUST be calculated using the original feed (before dupes removed)
  const isEmpty = deals?.[0]?.length === 0;
  const isLoadingInitialData = !deals && !error;

  // remove secondary deals from top of page 0
  if (
    deals &&
    canRemoveSecondary &&
    numberSecondaryDeals &&
    firstPageSize === deals?.[0].length
  ) {
    deals?.[0]?.splice(0, numberSecondaryDeals);
    setCanRemoveSecondary(false);
  }

  const pageLimitForGuidePage =
    isGuidepage && deals?.flat().length > totalDeals;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && deals && typeof deals[size - 1] === 'undefined');
  const actualPageSize = isSearchPage ? pageSize - 6 : pageSize;
  const isReachingEnd =
    isEmpty ||
    (deals && deals[deals.length - 1]?.length < actualPageSize) ||
    pageLimitForGuidePage;

  useVideoScroll(deals);

  useClientSideEffect(() => {
    getKeyRef.current = getKeyGenerator(startPageNr);
    // setSize to trigger rerender
    setSize(size);
  }, [startPageNr, getKeyGenerator, setSize, router.query]);

  useEffect(() => {
    // clear start page when user changes filters to avoid showing empty pages
    setStartPageNr(0);
  }, [router.query]);

  useEffect(() => {
    if (deals)
      dispatch({ deals: deals.flat(), type: TRACKING.SET_TRACKING_DEALS });
  }, [deals]);

  useClientSideEffect(() => {
    const handleComplete = () => {
      const dealCardElement = document.querySelector(
        `#deal_${restoreScrollData.dealId}`,
      );
      if (dealCardElement) {
        if (typeof dealCardElement.scrollIntoViewIfNeeded === 'undefined') {
          dealCardElement.scrollIntoView({
            block: 'center',
            inline: 'center',
          });
        } else {
          dealCardElement.scrollIntoViewIfNeeded();
        }

        setRestoreScrollData(null);
        scrollRetriesRef.current = 0;
      } else if (scrollRetriesRef.current <= DEAL_FOCUS_RETRIES.length) {
        ++scrollRetriesRef.current;
        setTimeout(
          handleComplete,
          DEAL_FOCUS_RETRIES[scrollRetriesRef.current],
        );
      } else {
        // Reset
        scrollRetriesRef.current = 0;
      }
    };

    if (restoreScrollData?.url === window.location.href) {
      // When the following if statement evaluates to true, it will set the startPageNr
      // to the value found in restoreScrollData.
      // The component will rerender and skip calling handleComplete as it
      // will be loading content and isValidating is true.
      // On the second useEffect call, when isValidating turns to false,
      // handleComplete gets called
      if (restoreScrollData.page !== startPageNr)
        setStartPageNr(restoreScrollData.page);
      if (!isValidating) handleComplete();
    }
  }, [
    router.asPath,
    restoreScrollData,
    setRestoreScrollData,
    isValidating,
    startPageNr,
    setStartPageNr,
  ]);

  const handleLoadPrevious = () => {
    const containerHeight = componentRef.current.getBoundingClientRect().height;
    componentRef.current.style.minHeight = `${containerHeight}px`;
    setStartPageNr(startPageNr - 1);
  };

  useClientSideEffect(() => {
    if (!isValidating && componentRef.current?.style.minHeight) {
      const height = componentRef.current
        .querySelector('.deal-block')
        .getBoundingClientRect().height;
      window.scrollBy(0, height * 2);
      componentRef.current.style.minHeight = '';
    }
  }, [isValidating]);

  const handleLoadMore = () =>
    !isLoadingMore && !isValidating && setSize(size + 1);

  const renderDeals = () => {
    // prevent bottom deals to show when no deals
    if (!deals?.length) return null;

    if (pageLimitForGuidePage) {
      deals = deals.flat().slice(0, totalDeals);
    }

    const dealsInBlocks = template({
      additionalContentText,
      deals,
      isVipHubPage,
      isWowcherIe,
      path: router.asPath,
      popularLocationsData,
      theme,
      type: templateType,
    });

    return (
      <div>
        {startPageNr > 0 && <LoadPrevious onClick={handleLoadPrevious} />}
        {dealsInBlocks}
        {deals.length > 0 && !isLoadingMore && !isReachingEnd && (
          <LoadMore onTrigger={handleLoadMore} />
        )}
      </div>
    );
  };

  if (isEmpty) {
    return emptyText ? (
      <div className="container">
        <p className="empty-text">{emptyText}</p>
        <style jsx>{`
          .empty-text {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: #888;
            padding: 40px 20px;
          }
        `}</style>
      </div>
    ) : null;
  }

  return (
    <div ref={componentRef}>
      {renderDeals()}
      {isLoadingMore && placeholderTemplate(templateType)}
      <EndOfFeed show={isReachingEnd} />
      <style global jsx>{`
        .container-wrapper .container .bottom-deals-block.sequence-blocks .medium-deal {
          margin-top: 20px;
        }
        .bottom-deals.deals-products-list .bottom-deals-block .main-deal {
          margin-bottom: 0px !important;
        }
        .bottom-deals.deals-products-list .sequence-of-3 {
          margin-top: 0px !important;
        }
        .ipad-row .medium-deal:first-child {
          margin-top: 20px;
        }
        .bottom-deals.deals-products-list .medium-deals-block {
          width: 100%;
        }
        .bottom-deals.deals-products-list .sequence-of-3 {
          margin-top: 20px;
          width: 100%;
        }
        .bottom-deals.deals-products-list .full-row {
          width: 100%;
          margin-bottom: 20px;
        }
        .bottom-deals.deals-products-list .row-display {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .bottom-deals.deals-products-list .cols-display {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
        .bottom-deals.deals-products-list .cols {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .bottom-deals.deals-products-list .bottom-deals-block.sequence-blocks .item {
          margin-bottom: 20px;
        }
        .bottom-deals.deals-products-list .nowrap {
          flex-wrap: nowrap;
        }
        @media (max-width: ${theme.breakpoints.xlDown}) {
          .ipad-row .medium-deal:last-child {
            margin-top: 20px;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .main-deal {
            padding-right: 0px;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .main-deal {
            width: 100%;
            margin-bottom: 0px;
            //margin-bottom: 20px;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals { width: 100%; height: auto;}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row {
            flex-direction: row;
            margin-bottom: 0px;
            width: 666px;
            margin: 0 auto;
          }
          .bottom-deals.deals-products-list .bottom-deals-block.sequence-blocks {
            width: 666px !important;
            margin: 0 auto;
          }
          .bottom-deals.deals-products-list .sequence-of-3 {
            margin-bottom: 0px;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .deal { width: 50%}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .deal { width: 50% !important; height: 250px;}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .deal :nth-child(even) {margin-left: 10px;}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .deal :nth-child(odd) {margin-right: 10px;}
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .bottom-deals.deals-products-list .container {
            padding: 0px;
          }
          .bottom-deals.deals-products-list .row {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 0px;
            margin-right: 0px;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .item { height: 300px; !important}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .deal { width: 100% !important; height: 300px !important}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row { flex-direction: column; }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row {
            width: 566px !important;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .bottom-deals.deals-products-list .bottom-deals-block.sequence-blocks {
            width: 566px !important;
          }
          .bottom-deals.deals-products-list .bottom-deals-block.sequence-blocks  .bottom-deal-item  {width: 100%;}
          .bottom-deals.deals-products-list .bottom-deals-block  .bottom-deal-item { width: 100%; }
          .bottom-deals.deals-products-list .bottom-deals-block .item { height: auto;}
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .deal:first-child { margin-bottom: 20px;}
          .bottom-deals-block.sequence-blocks {
            width: 566px;
          }
          .empty-medium-deal2 {
            display: none !important;
          }
        }
        @media (max-width: ${theme.breakpoints.smDown}) {
          .bottom-deals.deals-products-list .bottom-deals-block.sequence-blocks {
            width: 100% !important;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row {
            width: 100% !important;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row .medium-deal{
            margin-top: 20px;
          }
          .cols-display .ipad-row,
          .bottom-deals-block .side-deals .ipad-row {
            width: 100% !important;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row {
            margin: 0;
          }
          .empty-medium-deal {
            display: none !important;
          }
          .bottom-deals.deals-products-list .bottom-deals-block .side-deals .ipad-row .medium-deal,
          .container-wrapper .container .bottom-deals-block.sequence-blocks .medium-deal {
            margin-top: 5px;
          }
        }
      `}</style>
    </div>
  );
};

ConnectedBottomDeals.propTypes = {
  additionalContentText: PropTypes.string,
  emptyText: PropTypes.any,
  fetcherFunction: PropTypes.func.isRequired,
  firstPageSize: PropTypes.number,
  getKeyGenerator: PropTypes.func.isRequired,
  isGuidepage: PropTypes.bool,
  isSearchPage: PropTypes.bool,
  numberSecondaryDeals: PropTypes.number,
  pageSize: PropTypes.number,
  popularLocationsData: PropTypes.array,
  sponsoredSearchDeals: PropTypes.array,
  templateType: PropTypes.string,
  totalDeals: PropTypes.number,
};

export default ConnectedBottomDeals;
