import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { BOUGHT_COUNT_CUE, FEATURE_FLAGS } from '../../config/setup/setup';
import { REMAINING_TEXT, BOUGHT_TEXT } from '../../config/text/text';
import { isCreditecDeal } from '../../helpers/creditec';
import ThemeContext from '../../providers/ThemeProvider';

const MainDealBoughtCount = ({ deal = null, center }) => {
  const theme = useContext(ThemeContext);

  if (!deal?.display) {
    return null;
  }

  if (FEATURE_FLAGS[BOUGHT_COUNT_CUE] && deal.display.quantityRemaining) {
    return (
      <>
        <div className="deal-main-deal__remain">
          <span className="deal-main-deal__remain-number">
            {deal.totalRemaining}
          </span>{' '}
          <span className="deal-main-deal__remain-text">{REMAINING_TEXT}</span>
        </div>
        <style jsx>{`
          .deal-main-deal__remain {
            ${center ? 'text-align: center' : ''};
            color: ${theme.colors.primary};
            font-weight: bold;
            padding: 8px 0;
          }
          .deal-main-deal__remain-number {
            font-size: 22px;
            vertical-align: middle;
            line-height: 1em;
          }
          .deal-main-deal__remain-text {
            font-size: 14px;
            vertical-align: middle;
            text-transform: capitalize;
          }
        `}</style>
      </>
    );
  } else if (
    FEATURE_FLAGS[BOUGHT_COUNT_CUE] &&
    deal.display.bought &&
    deal.totalBought > 0
  ) {
    return (
      <>
        <p className="deal-main-deal__bought">
          <span className="deal-main-deal__bought-number">
            {deal.totalBought}
          </span>{' '}
          <span className="deal-main-deal__bought-text">{BOUGHT_TEXT}</span>
        </p>
        <style jsx>{`
          .deal-main-deal__bought {
            ${center ? 'text-align: center' : ''};
            color: ${theme.colors.primary};
            font-weight: bold;
            padding: 8px 0;
            margin: 0;
          }
          .deal-main-deal__bought-number {
            font-size: 22px;
            vertical-align: middle;
            line-height: 1em;
          }
          .deal-main-deal__bought-text {
            font-size: 14px;
            vertical-align: middle;
            text-transform: capitalize;
          }

          @media (min-width: ${theme.breakpoints.mdUp}) {
            :global(.main-deal-travel
                .deal-main-deal__bought
                .deal-main-deal__bought-number) {
              font-size: 30px;
            }
            :global(.main-deal-travel
                .deal-main-deal__bought
                .deal-main-deal__bought-text) {
              font-size: 20px;
            }
          }
        `}</style>
      </>
    );
  } else if (deal.totalBought === 0 && !isCreditecDeal(deal)) {
    return (
      <>
        <p className="deal-main-deal__new-today">
          <span>{deal.newDealMessageTag}</span>
        </p>
        <style jsx>{`
          .deal-main-deal__new-today {
            color: ${theme.colors.primary};
            text-transform: uppercase;
            padding: 8px 0;
            font-weight: bold;
            width: 120px;
            ${center ? 'text-align: center' : ''};
            font-size: 18px;
            display: none;
            margin: 0;
          }
          .deal-main-deal__bought-text--normal {
            font-weight: normal;
          }
          @media (max-width: ${theme.breakpoints.mdDown}) {
            .deal-main-deal__new-today {
              display: block;
            }
          }
        `}</style>
      </>
    );
  } else {
    return null;
  }
};

MainDealBoughtCount.propTypes = {
  center: PropTypes.bool,
  deal: PropTypes.object,
};

export default MainDealBoughtCount;
