import { useState, useEffect } from 'react';

export const useHeight = (
  breakpoint,
  isShopLayout,
  isLocation,
  MapViewEnabled,
  isMapCategoryPage,
) => {
  const [height, setHeight] = useState(75);

  useEffect(() => {
    switch (breakpoint) {
      case 'xs': {
        setHeight(75);
        break;
      }

      case 'sm': {
        setHeight(!isShopLayout && !isLocation ? 75 : 218);
        break;
      }

      case 'md': {
        setHeight(263);
        break;
      }

      case 'lg': {
        setHeight(263);
        break;
      }

      case 'xl': {
        setHeight(
          (isLocation || isMapCategoryPage) && MapViewEnabled ? 115 : 220,
        );
        break;
      }

      default: {
        setHeight(220);
        break;
      }
    }
  }, [breakpoint, isShopLayout, isLocation]);

  return height;
};
