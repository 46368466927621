/* eslint-disable sonarjs/cognitive-complexity, react-hooks/exhaustive-deps, react/forbid-component-props */
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Proptypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  HIDE_FOOTER,
  SHOW_FOOTER,
  BACK_TO_TOP,
  VIEW_FOOTER,
} from '../../config/text/text';
import { scrollToTop } from '../../helpers/infiniteScroll';
import { getLocationShortName } from '../../helpers/location';
import { isDesktop, isMobileViewport } from '../../helpers/screen';
import ThemeContext from '../../providers/ThemeProvider';
import { setFooterValue } from '../../redux/actions/footer';
import { setLastId } from '../../redux/actions/setDeals';
import Footer from '../footer/FooterDynamic';

const Pagination = ({ showPagination = false }) => {
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const dispatch = useDispatch();
  const [footer, setFooter] = useState(false);
  const showFooter = () => {
    setFooter(true);
  };
  const [pagination, setPagination] = useState(false);
  const [location, deals, mainDeal, footerShow] = useSelector((state) => [
    state.locations.location,
    state.deals.deals,
    state.deals.mainDeal,
    state.footer.show,
  ]);
  const hideFooter = () => {
    setFooter(false);
  };
  const shortName = getLocationShortName(location);
  const scrollMaxThreshold = 2_000;
  const isWishlist = router.pathname.includes('wishlist');

  const handleBackToTop = () => {
    scrollToTop(router, 0);
    setTimeout(() => {
      dispatch(setLastId(0));
    }, 100);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setFooter(false);
    });
  }, [router]);

  useEffect(() => {
    setFooter(false);
    if (deals?.length >= 0 && deals?.length < 9) {
      showFooter();
    }
  }, [deals]);

  useEffect(() => {
    if (showPagination) {
      setPagination(true);
    }
  }, []);

  useEffect(() => {
    hideFooter();
  }, [deals]);

  useEffect(() => {
    const noDealsOrMainDeal = !deals?.length || !mainDeal;

    const allDealsLoaded = () => {
      const allLoadedVisible = document
        ?.querySelector('.all-loaded')
        ?.getBoundingClientRect().top;
      if (allLoadedVisible >= 0 && allLoadedVisible <= window.innerHeight) {
        setPagination(false);
        setFooter(true);
      } else {
        setPagination(true);
        hideFooter();
      }
    };

    // TODO : add unit tests for complicated case scenarios and not breaking page or refactor this
    const showPaginationBar = () => {
      const lastKnownScrollPosition = window.scrollY;
      const containerLoaded = document.querySelector('.container.all-loaded');
      const pageNotFound = document.querySelector('.not-found__content');

      if ((isMobileOnly || isMobileViewport()) && !footerShow) {
        setPagination(false);
        if (noDealsOrMainDeal) {
          hideFooter();
        }
      } else if (pageNotFound) {
        hideFooter();
      } else if (containerLoaded || noDealsOrMainDeal) {
        allDealsLoaded();
      } else if (
        lastKnownScrollPosition > scrollMaxThreshold ||
        footerShow ||
        (lastKnownScrollPosition > 1_000 &&
          lastKnownScrollPosition < scrollMaxThreshold &&
          deals.length >= 8 &&
          deals.length < 13 &&
          isDesktop() &&
          !isMobileOnly)
      ) {
        if (footerShow) {
          hideFooter();
          dispatch(setFooterValue(false));
        } else {
          setPagination(true);
          hideFooter();
        }
      } else if (deals?.length >= 0 && deals?.length < 9) {
        showFooter();
        setPagination(false);
      } else {
        setPagination(false);
      }
    };

    if (!isWishlist) {
      window.addEventListener('scroll', showPaginationBar);
      window.addEventListener('resize', showPaginationBar);
    }

    return () => {
      if (!isWishlist) {
        window.removeEventListener('scroll', showPaginationBar);
        window.addEventListener('resize', showPaginationBar);
      }
    };
  }, [deals, mainDeal, isWishlist, footerShow]);

  return (
    <>
      <div
        className={classNames('footer-wrapper', {
          'footer-wrapper--fixed': pagination || footerShow,
        })}
      >
        {(pagination || footerShow) && (
          <div
            className={`pagination ${
              footer || footerShow
                ? 'pagination-expanded'
                : 'pagination-collapsed'
            }`}
          >
            {isDesktop() && (
              <span
                className="pagination-side"
                onClick={handleBackToTop}
                onKeyPress={handleBackToTop}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon
                  className="chevron"
                  icon={faAngleUp}
                  title={BACK_TO_TOP}
                />
                <span>{BACK_TO_TOP}</span>
              </span>
            )}
            {!footer && isDesktop() && (
              <div
                className="pagination-side"
                onClick={() => showFooter()}
                onKeyPress={() => showFooter()}
                role="button"
                tabIndex={0}
              >
                {SHOW_FOOTER}
                <FontAwesomeIcon
                  className="chevron"
                  icon={faAngleDown}
                  title={VIEW_FOOTER}
                />
              </div>
            )}
            {(footer || footerShow) && (
              <div
                className="pagination-side"
                onClick={() => {
                  hideFooter();
                  if (footerShow) {
                    dispatch(setFooterValue(false));
                  }
                }}
                onKeyPress={() => {
                  if (footerShow) {
                    dispatch(setFooterValue(false));
                  }
                  hideFooter();
                }}
                role="button"
                tabIndex={0}
              >
                {HIDE_FOOTER}
                <FontAwesomeIcon
                  className="chevron"
                  icon={faAngleUp}
                  title="Hide Footer"
                />
              </div>
            )}
          </div>
        )}
        {(footer || footerShow) && (
          <div className="footer">
            <Footer
              isSecurePage={false}
              locationShortName={shortName}
              site={process.env.NEXT_PUBLIC_SITE}
            />
          </div>
        )}
      </div>
      <style jsx>{`
        .footer-wrapper {
          background-color: #e8e8e8;
          width: 100%;
        }
        .footer-wrapper--fixed {
          bottom: 0;
          left: 0;
          position: fixed;
          z-index: 1000;
        }
        .pagination {
          align-content: center;
          background-color: #e8e8e8;
          display: flex;
          height: 60px;
          padding: 10px;
          justify-content: space-around;
          width: 100%;
          z-index: 1000;
        }
        .pagination-side {
          cursor: pointer;
        }
        :global(.chevron) {
          color: ${theme.colors.primary};
          cursor: pointer;
          margin: 0 10px;
          font-size: 48px;
          vertical-align: middle;
        }
        .footer :global(.footer) {
          min-height: 300px;
        }
      `}</style>
    </>
  );
};

Pagination.propTypes = {
  showPagination: Proptypes.bool,
};

export default Pagination;
