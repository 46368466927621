import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { VIP_QUALIFIERS } from '../config/constants/page-types';
import { LINK_CONTACT_US } from '../config/links/links';
import {
  VIP_CONTACT_TITLE,
  VIP_CONTACT_DESC,
  VIP_CONTACT_TEXT,
} from '../config/text/text';
import { parseWowcherPath } from '../helpers/url';
import ThemeContext from '../providers/ThemeProvider';

const VipContact = () => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const { pageType } = parseWowcherPath(router.asPath);
  if (!VIP_QUALIFIERS.includes(pageType)) {
    return null;
  }

  const openContactLink = () => {
    window.open(LINK_CONTACT_US);
  };

  return (
    <>
      <div className="vip-banner-contact container ">
        <div className="vip-banner-hero ">
          <h2 className="vip-banner-title-contact">{VIP_CONTACT_TITLE}</h2>
        </div>
        <p className="vip-banner-desc-contact">{VIP_CONTACT_DESC}</p>

        <div className="contact-btn" onClick={openContactLink}>
          <div className="btn-text">{VIP_CONTACT_TEXT}</div>
        </div>
      </div>
      <style jsx>{`
        .btn-text {
          padding: 6px 20px;
          text-align: center;
          font-size: 18px;
          text-transform: uppercase;
          background: white;
          color: black;
          font-weight: 600;
          display: inline-block;
          border-radius: 8px;
          margin: 0px auto;
        }
        .contact-btn {
          width: 100%;
          display: flex;
          cursor: pointer;
        }
        .vip-banner-contact {
          width: 100%;
          margin-bottom: 20px;
          margin-top: 20px;
          color: white;
          padding: 20px;
          border: 1px solid;
          border-style: double;
          border-width: 6px;
        }
        .vip-banner-hero {
          margin: 0 20px;
          display: flex;
          gap: 0 2rem;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }
        .vip-logo {
          max-width: 108px;
          padding: 10px;
        }
        .vip-banner-title-contact {
          text-align: center;
          font-size: 18px;
          text-transform: uppercase;
        }
        .vip-banner-desc-contact {
          text-align: center;
          font-size: 18px;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .vip-banner {
            display: flex;
            font-size: 20px;
            flex-flow: column;
            margin: 20px auto;
          }
        }

        @media (min-width: ${theme.breakpoints.xlUp}) {
          .vip-banner-title {
            flex: 8;
            font-size: 36px;
          }
          .vip-banner-desc {
            margin-left: 8rem;
            font-weight: 300;
            box-sizing: border-box;
          }
        }
      `}</style>
    </>
  );
};

export default VipContact;
