import React, { useRef, useEffect } from 'react';
import 'intersection-observer';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

const LoadMore = ({ onTrigger = noop }) => {
  const whiskerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([observerEntry]) => {
        if (observerEntry.isIntersecting) {
          onTrigger();
        }
      },
      {
        root: null,
        rootMargin: '0px 0px 200px 0px',
        threshold: 0.01,
      },
    );

    observer.observe(whiskerRef.current);

    return () => observer.disconnect();
  }, [onTrigger, whiskerRef]);

  return (
    <>
      <div className="load-more" data-testid="load-more">
        <div className="load-more__whisker" ref={whiskerRef} />
      </div>
      <style jsx>{`
        .load-more {
          height: 1px;
          width: 1px;
          position: relative;
        }

        .load-more__whisker {
          width: 100%;
          height: 500px;
          position: absolute;
          bottom: 0;
        }
      `}</style>
    </>
  );
};

LoadMore.propTypes = {
  onTrigger: PropTypes.func.isRequired,
};

export default LoadMore;
