import { scroller } from 'react-scroll';
import {
  CHOOSE_YOUR_OPTIONS_ACTIONS,
  SCROLL_INLINE_CALENDAR,
  CHOOSE_YOURS_OPTIONS_DEAL,
  CHOOSE_YOUR_OPTIONS_COMPONENT,
  DROPDOWN_PRODUCT_PAGE,
} from '../config/constants/scroll';
import {
  isBookingCalendarChooseDateDeal,
  getIsChooseYourOptionsDeal,
  getIsChooseYourProductDeal,
} from './deals';
import { getScreenBreakpoint } from './screen';

export const mainDealLayoutType = {
  about: 'about',
  actions: 'actions',
};

// Check what element to scroll to based on type of button and layout condition for component
export const getScrollElementDealButtonType = (
  deal,
  layout = mainDealLayoutType.actions,
  isTravel = false,
  // eslint-disable-next-line consistent-return
) => {
  if (isBookingCalendarChooseDateDeal(deal)) {
    return SCROLL_INLINE_CALENDAR;
  } else if (getIsChooseYourOptionsDeal(deal)) {
    return CHOOSE_YOURS_OPTIONS_DEAL;
  } else if (getIsChooseYourProductDeal(deal)) {
    // CHOOSE_YOUR_OPTIONS_ACTIONS exists only for Travel pages
    return layout === mainDealLayoutType.actions && isTravel
      ? CHOOSE_YOUR_OPTIONS_ACTIONS
      : CHOOSE_YOUR_OPTIONS_COMPONENT;
  }
};

export const scrollToProductPageDropDown = () => {
  const breakpoint = getScreenBreakpoint();
  scroller.scrollTo(DROPDOWN_PRODUCT_PAGE, {
    delay: 100,
    duration: 1_000,
    offset: breakpoint === 'sm' ? -500 : -100,
    smooth: true,
  });
};
