import dynamic from 'next/dynamic';

/**
 * The library used manipulates the DOM directly so cannot be used with ssr
 * Dynamically importing on client only solves the problem
 */
const DynamicMapWidget = dynamic(() => import('./MapViewWidget'), {
  ssr: false,
});
export default DynamicMapWidget;
