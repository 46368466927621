import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { VIP_LOGO } from '../config/constants/images';
import { VIP_QUALIFIERS } from '../config/constants/page-types';
import {
  VIP,
  VIP_HUB_BANNER_DESC,
  VIP_HUB_BANNER_TITLE,
} from '../config/text/text';
import { parseWowcherPath } from '../helpers/url';
import ThemeContext from '../providers/ThemeProvider';

const VipBanner = () => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const { pageType } = parseWowcherPath(router.asPath);
  if (!VIP_QUALIFIERS.includes(pageType)) {
    return null;
  }

  return (
    <>
      <div className="vip-banner">
        <div className="vip-banner-hero">
          <img
            alt={VIP}
            className="vip-logo"
            src={VIP_LOGO[process.env.NEXT_PUBLIC_BRAND]}
          />
          <h2 className="vip-banner-title">{VIP_HUB_BANNER_TITLE}</h2>
        </div>
        <p className="vip-banner-desc">{VIP_HUB_BANNER_DESC}</p>
      </div>
      <style jsx>{`
        .vip-banner {
          width: 100%;
          margin-bottom: 20px;
          color: white;
          padding: 0px 20px;
        }
        .vip-banner-hero {
          margin: 0 20px;
          display: flex;
          gap: 0 2rem;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }
        .vip-logo {
          max-width: 144px;
          padding: 10px;
        }
        .vip-banner-title {
          text-align: center;
          font-size: 24px;
          text-transform: uppercase;
          color: white;
        }
        .vip-banner-desc {
          text-align: center;
          font-size: 18px;
          color: white;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .vip-banner {
            display: flex;
            font-size: 20px;
            flex-flow: column;
            margin: 20px auto;
          }
        }

        @media (min-width: ${theme.breakpoints.xlUp}) {
          .vip-banner-title {
            font-size: 44px;
          }
          .vip-banner-desc {
            font-weight: 300;
            box-sizing: border-box;
          }
        }
      `}</style>
    </>
  );
};

export default VipBanner;
