export const MAP_ZOOM_LEVEL_DEALS = {
  6: 200,
  6.5: 200,
  7: 200,
  7.5: 200,
  8: 200,
  8.5: 200,
  9: 200,
  9.5: 200,
  10: 200,
  10.5: 200,
  11: 200,
  11.5: 200,
  12: 150,
  12.5: 150,
  13: 150,
  13.5: 150,
  14: 80,
  14.5: 80,
  15: 80,
  15.5: 80,
  16: 80,
};
export const MAP_ZOOM_LEVEL_DEALS_MOBILE = {
  6: 100,
  6.5: 100,
  7: 100,
  7.5: 100,
  8: 100,
  8.5: 100,
  9: 100,
  9.5: 100,
  10: 80,
  10.5: 80,
  11: 80,
  11.5: 80,
  12: 40,
  12.5: 40,
  13: 40,
  13.5: 40,
  14: 40,
  14.5: 40,
  15: 40,
  15.5: 40,
  16: 40,
};

export const MAP_DEALS_DEFAULT = 200;
export const MAP_DEALS_DEFAULT_MOBILE = 80;
export const MAP_ZOOM_DEFAULT = 10;
export const MAP_ZOOM_FIRST_LOAD_DEFAULT = 11;
export const MAP_ZOOM_SNAP_DEFAULT = 1;
export const MAP_ZOOM_MIN = 6;
export const MAP_ZOOM_MAX = 16;
export const MAP_API_DEBOUNCE_TIMEOUT = 750;
export const MAP_API_SLOW_TIME = 2_000;
export const MAP_LAT_LON_DP = 3;
export const MAP_DRAG_BEFORE_UPDATE = 80; // px
export const MAP_MAX_CAROUSEL = 200;
export const MAP_MAX_CAROUSEL_MOBILE = 100;

export const MAP_API_ERROR_TEXT = 'Sorry there was a problem';

export const ALL_CATEGORY_FILTERS = [
  {
    name: 'Activities',
    shortName: 'activities',
    value: 'activities',
  },
  {
    name: 'Beauty',
    shortName: 'beauty',
    value: 'beauty',
  },
  {
    name: 'Entertainment',
    shortName: 'entertainment',
    value: 'entertainment',
  },
  {
    name: 'Food and Drink',
    shortName: 'drinking',
    value: 'drinking',
  },
  {
    name: 'Healthcare',
    shortName: 'healthcare',
    value: 'healthcare',
  },
  {
    name: 'Restaurants & Bars',
    shortName: 'restaurantandfood',
    value: 'restaurantandfood',
  },
  {
    name: 'Spas & Country House',
    shortName: 'spascountryhouse',
    value: 'spascountryhouse',
  },
  {
    name: 'Sports & Adventure',
    shortName: 'sportsadventure',
    value: 'sportsadventure',
  },
  {
    name: 'UK City Breaks',
    shortName: 'ukcitybreaks',
    value: 'ukcitybreaks',
  },
  {
    name: 'UK Seaside',
    shortName: 'ukseaside',
    value: 'ukseaside',
  },
];
