import { useEffect, useState } from 'react';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import {
  URLCREATERETURN,
  URLGENERATEVOUCHER,
  URLPROMOCATEGORY,
  URLPROMOSUBCATEGORY,
  URLUPDATEVOUCHER,
} from '../config/setup/setup';
import httpCommonHeaders from '../helpers/httpCommonHeaders';
import { isWowcherBrand } from '../helpers/pages';
import { prepareToOpenPdf } from '../helpers/pdf';

export const fetchVoucherPage = async (url) => {
  const { data } = await axios(url, {
    headers: httpCommonHeaders(),
    method: 'GET',
    withCredentials: true,
  });

  return data || [];
};

export const fetchRedeemInstructions = async (voucher) => {
  if (voucher?.dealBundleChildren?.length > 0) {
    return voucher.dealBundleChildren.map((instruction) => ({
      info: isWowcherBrand()
        ? instruction.redemptionInstructionsWowcherPdf
        : instruction.redemptionInstructionsLivingSocialPdf,
      title: instruction.title,
    }));
  }

  const { data } = await axios(
    `${process.env.NEXT_PUBLIC_LEGACY_API}/deal/redeem-instructions/${voucher.voucherCode}`,
    {
      headers: httpCommonHeaders(),
      method: 'GET',
      withCredentials: true,
    },
  );

  if (data?.redemptionInfo?.redemptionInstructions) {
    // Create an array so the view doesn't have to have a special case for bundles.
    return [{ info: data.redemptionInfo.redemptionInstructions }];
  } else {
    return [];
  }
};

export const useRedeemInstructions = ({ voucher, active }) => {
  const [instructions, setInstructions] = useState(undefined);
  useEffect(() => {
    if (active && !instructions) {
      (async () => {
        const result = await fetchRedeemInstructions(voucher);
        setInstructions(result);
      })();
    }
  }, [voucher, active, instructions]);

  return instructions;
};

export const generateVoucher = async (voucherCode) => {
  const response = await axios({
    cache: false,
    headers: httpCommonHeaders(),
    method: 'GET',
    responseType: 'arraybuffer',
    url: `${URLGENERATEVOUCHER}/${voucherCode}`,
  });

  return prepareToOpenPdf(response?.data);
};

export const updateVoucher = async (voucherCode, patchValue) => {
  await axios({
    cache: false,
    data: patchValue,
    headers: httpCommonHeaders(),
    method: 'PATCH',
    url: `${URLUPDATEVOUCHER}/${voucherCode}`,
  });
};

export const createReturnOrder = async (voucherCode, isCashRefund) => {
  const paymentType = isCashRefund ? 'original-payment-source' : 'full-credit';
  const response = await axios({
    cache: false,
    headers: httpCommonHeaders(),
    method: 'GET',
    url: `${URLCREATERETURN}/${voucherCode}?autoRefundType=${paymentType}`,
  });

  return response.data;
};

export const getPromocode = async (pathUrl) => {
  let url;
  if (pathUrl.details.subCategory) {
    url = `${URLPROMOSUBCATEGORY}/${pathUrl?.details.subCategory}`;
  } else if (pathUrl.details.category) {
    url = `${URLPROMOCATEGORY}/${pathUrl?.details.category}`;
  }
  const response = await axios({
    cache: false,
    headers: httpCommonHeaders(),
    method: 'GET',
    url,
  });

  return response.data;
};

export const getSubCategoryPromocode = async (subCategory) => {
  const response = await axios({
    cache: false,
    headers: httpCommonHeaders(),
    method: 'GET',
    url: `${URLPROMOSUBCATEGORY}/${subCategory}`,
  });

  return response.data;
};
