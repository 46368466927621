import { isMobile } from 'react-device-detect';

export const FilterButtonWrapper = {
  position: 'relative',
};

export const FilterButtonStyle = ({ open, theme }) => ({
  appearance: 'none', // style for native select
  background: 'none', // style for native select
  border: '1px solid #fff',
  borderRadius: '4px',
  color: open ? theme.colors.primary : '#fff',
  cursor: 'pointer',
  fontSize: isMobile ? '15px' : '19px',
  fontWeight: '400',
  padding: '10px 40px 10px 14px',
  position: 'relative',
  textAlign: 'left',

  width: '100%',
  // style for native select
  zIndex: '2',
});

export const FilterIconStyle = ({ open, theme }) => ({
  alignItems: 'center',
  bottom: '0',
  color: open ? theme.colors.primary : '#fff',
  display: 'flex',
  padding: '12px',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: '-1',
});
