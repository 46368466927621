/* eslint-disable @next/next/no-img-element, react/no-array-index-key, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, no-template-curly-in-string */
import Link from 'next/link';
import React, { useContext } from 'react';
import Cookies from 'react-cookies';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import COOKIES from '../../config/cookies/cookies';
import {
  DEFAULT_LOCATION,
  DESKTOP_TILE_IMG_HEIGHT,
  DESKTOP_TILE_IMG_WIDTH,
  MOBILE_TILE_IMG_HEIGHT,
  MOBILE_TILE_IMG_WIDTH,
} from '../../config/setup/setup';
import { decodeURL } from '../../helpers/decodeURL';
import {
  replaceTileImageServer,
  usePlaceholderImage,
} from '../../helpers/image';
import { redirectToURL } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';

const replaceUrlLocation = (linkUrl) => {
  const decodedUrl = decodeURL(linkUrl);
  let returnUrl = decodedUrl;
  const locationCookie = Cookies.load(COOKIES.location);
  if (decodedUrl.includes('${location}')) {
    const locationReplace =
      locationCookie?.shortName || DEFAULT_LOCATION.shortName;
    returnUrl = decodedUrl.replace('${location}', locationReplace);
  }

  return returnUrl;
};

const Tiles = () => {
  const [tiles] = useSelector((state) => [state.tiles.tiles]);
  const theme = useContext(ThemeContext);
  const tilesMax = isMobileOnly ? 3 : 4;
  if (tiles?.length === 0 || tiles === null) return null;

  return (
    <>
      <div className="tiles" id="giftingtilesContainer">
        {tiles?.slice(0, tilesMax).map((tile, index) => (
          <div className="tile__item" key={index}>
            <Link href={replaceUrlLocation(tile.linkUrl)} passHref>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  redirectToURL(replaceUrlLocation(tile.linkUrl));
                }}
              >
                <picture>
                  <source
                    height={DESKTOP_TILE_IMG_HEIGHT}
                    media={`(min-width: ${theme.breakpoints.mdUp})`}
                    srcSet={replaceTileImageServer({
                      height: DESKTOP_TILE_IMG_HEIGHT,
                      imageUrl: tile.desktopImgUrl,
                      width: DESKTOP_TILE_IMG_WIDTH,
                    })}
                    width={DESKTOP_TILE_IMG_WIDTH}
                  />
                  <img
                    alt={tile.name}
                    className="tile__image"
                    height={MOBILE_TILE_IMG_HEIGHT}
                    onError={usePlaceholderImage}
                    src={replaceTileImageServer({
                      height: MOBILE_TILE_IMG_HEIGHT,
                      imageUrl: tile.mobileImgUrl,
                      width: MOBILE_TILE_IMG_WIDTH,
                    })}
                    width={MOBILE_TILE_IMG_WIDTH}
                  />
                </picture>
              </a>
            </Link>
          </div>
        ))}
      </div>

      <style jsx>{`
        .tiles {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          margin: 0;
          width: 100%;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .tiles {
            margin: 20px 0;
          }
        }
        .tile__item {
          flex-basis: 0px;
          flex-grow: 1;
          margin: 2px;
          text-align: center;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .tile__item {
            margin: 0 5px;
          }
          .tile__item:first-child {
            margin-left: 0;
          }
          .tile__item:last-child {
            margin-right: 0;
          }
        }
        .tile__image {
          height: auto;
          max-width: 100%;
          width: auto;
        }
      `}</style>
    </>
  );
};

export default Tiles;
