import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ThemeContext from '../../../providers/ThemeProvider';
import WowModal from '../../WowModal';
import RangeSelector from '../range/RangeSelectorDynamic';
import FilterButton from './FilterButton';
import { FilterButtonStyle, FilterIconStyle } from './FilterButtonStyle';

const FilterRange = ({ label, value, min, max, marks, onChange, onReset }) => {
  const theme = useContext(ThemeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  if (isMobile) {
    return (
      <>
        <button
          onClick={() => setModalOpen((s) => !s)}
          style={FilterButtonStyle({ open: false, theme })}
        >
          {label}
          <span style={FilterIconStyle({ open: false, theme })}>
            <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: 24 }} />
          </span>
        </button>
        <WowModal
          ariaLabelledBy=""
          centered
          extraClasses="range-modal"
          setShowModal={setModalOpen}
          showModal={modalOpen}
        >
          <RangeSelector
            label={label}
            marks={marks}
            max={max}
            min={min}
            onChange={(value_) => {
              onChange(value_);
              setModalOpen(false);
            }}
            onReset={onReset}
            value={value}
          />
        </WowModal>
        <style jsx>{`
          :global(.range-modal .modal-body) {
            padding: 0 40px 20px 40px;
          }
        `}</style>
      </>
    );
  } else {
    return (
      <>
        <FilterButton
          label={label}
          onClick={() => setOpen((o) => !o)}
          open={open}
        >
          <div className="range-container">
            <RangeSelector
              inline
              marks={marks}
              max={max}
              min={min}
              onChange={onChange}
              onReset={onReset}
              value={value}
            />
          </div>
        </FilterButton>
        <style jsx>{`
          .range-container {
            padding: 20px 50px 50px 50px;
          }
        `}</style>
      </>
    );
  }
};

FilterRange.propTypes = {
  label: PropTypes.string,
  marks: PropTypes.shape({
    label: PropTypes.string,
    style: PropTypes.object,
  }),
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number),
};

export default FilterRange;
