/* eslint-disable id-match */
import PropTypes from 'prop-types';
import React from 'react';
import {
  DEFAULT_DEAL_IMG_HEIGHT,
  DEFAULT_DEAL_IMG_WIDTH,
} from '../../../../config/setup/setup';
import CarouselImage from '../carousel/CarouselImage';
import CarouselThumbs from '../carousel/CarouselThumbs';

const CarouselImageBlock = ({
  currentImage,
  deal,
  dealUrl,
  disableClickAction,
  height = DEFAULT_DEAL_IMG_HEIGHT,
  image,
  index,
  invert,
  items,
  lazy,
  onClick,
  secondCheckout,
  setCurrentImage,
  showImageOverlays,
  showScrim,
  showSingle,
  thumbCount,
  thumbWidth,
  width = DEFAULT_DEAL_IMG_WIDTH,
  imageVariant,
}) => (
  <>
    <CarouselImage
      deal={deal}
      disableClickAction={disableClickAction}
      height={height}
      href={dealUrl}
      image={image}
      imageVariant={imageVariant}
      index={index}
      key={image.id || index}
      lazy={lazy}
      load={
        currentImage === index ||
        currentImage === index + 1 ||
        currentImage === index - 1 ||
        (currentImage === 0 && index === items.length - 1)
      }
      onClick={onClick}
      secondCheckout={secondCheckout}
      showImageOverlays={showImageOverlays}
      showScrim={showScrim}
      width={width}
    />
    {showSingle && (
      <CarouselThumbs
        deal={deal}
        invert={invert}
        onSelect={(index_) => setCurrentImage(index_)}
        selection={currentImage}
        thumbCount={thumbCount}
        thumbWidth={thumbWidth}
      />
    )}
  </>
);

CarouselImageBlock.propTypes = {
  currentImage: PropTypes.number,
  deal: PropTypes.object,
  dealUrl: PropTypes.string,
  disableClickAction: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.object,
  imageVariant: PropTypes.string,
  index: PropTypes.number,
  invert: PropTypes.bool,
  items: PropTypes.array,
  lazy: PropTypes.bool,
  onClick: PropTypes.func,
  secondCheckout: PropTypes.bool,
  setCurrentImage: PropTypes.func,
  showImageOverlays: PropTypes.bool,
  showScrim: PropTypes.bool,
  showSingle: PropTypes.bool,
  thumbCount: PropTypes.number,
  thumbWidth: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CarouselImageBlock;
