/* eslint-disable react/no-unknown-property */
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'react-cookies';
import { REFEREE_ALREADY_REGISTERED_BANNER } from '../../config/constants/images';
import COOKIES from '../../config/cookies/cookies';
import {
  MINI_MENU_RAF,
  REFEREE_REGISTERED_BANNER_HEADING,
  REFEREE_REGISTERED_BANNER_TEXT,
  START_REFERRING,
} from '../../config/text/text';
import { removeCookie } from '../../helpers/cookieSetter';
import { redirectToRoute } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import WowModal from '../WowModal';
import PrimaryButton from '../_generic/button/PrimaryButton';

const RefereeAlreadyRegisteredBanner = () => {
  const theme = useContext(ThemeContext);
  const [isRefereeBanner, setIsRefereeBanner] = useState(true);
  const router = useRouter();
  useEffect(() => {
    setIsRefereeBanner(Cookies.load(COOKIES.referralCode));
  }, []);

  return (
    <>
      <WowModal
        ariaLabelledBy="referee-already-registered-banner"
        centered
        extraClasses={'referee-welcome-modal no-body-padding'}
        setShowModal={(value) => {
          setIsRefereeBanner(value);
          removeCookie(COOKIES.referralCode);
        }}
        showCloseButton={false}
        showHeader={false}
        showModal={isRefereeBanner}
        size={'md'}
      >
        <div className="main-container">
          <div className="main-content__right">
            <div className="main-content__heading">
              <div className="text--lg">
                {REFEREE_REGISTERED_BANNER_HEADING}
              </div>
              <div className="text--md">{REFEREE_REGISTERED_BANNER_TEXT}</div>
            </div>
            <PrimaryButton
              className="fit-content size-medium"
              onClick={() => {
                setIsRefereeBanner(false);
                removeCookie(COOKIES.referralCode);
                redirectToRoute(MINI_MENU_RAF, router);
              }}
            >
              {START_REFERRING}
            </PrimaryButton>
          </div>
        </div>
      </WowModal>
      <style jsx>{`
        .main-container {
          font-size: 16px;
          font-weight: 200;
          background: url(${REFEREE_ALREADY_REGISTERED_BANNER});
          background-size: cover;
          text-align: center;
        }
        .main-content__right {
          width: 55%;
          height: 100%;
          padding: 1.5rem 0.6rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          margin-left: auto;
          text-transform: uppercase;
        }
        .text--lg {
          font-size: 26px;
          line-height: 30px;
          font-weight: 800;
        }
        .text--md {
          font-size: 16px;
          font-weight: 500;
        }
        .text--sm {
          font-size: 10px;
        }
        .banner--text {
          font-size: 20px;
          max-width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .banner--text > span {
          line-height: 22px;
        }

        .text--bold {
          font-weight: 600;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .main-content__right {
            width: 55%;
            padding: 3rem 0.5rem;
            gap: 1rem;
          }
          .text--lg {
            font-size: 26px;
            line-height: 40px;
            font-weight: 800;
          }
          .text--md {
            font-size: 18px;
            font-weight: 500;
          }
          .text--sm {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );
};

export default RefereeAlreadyRegisteredBanner;
