import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ALL_DEALS_LOADED } from '../../../config/text/text';
import ThemeContext from '../../../providers/ThemeProvider';

const EndOfFeed = ({ show }) => {
  const theme = useContext(ThemeContext);

  if (!show) return null;

  return (
    <div className="container all-loaded">
      <p className="all-loaded__text">{ALL_DEALS_LOADED}</p>
      <style global jsx>{`
        .all-loaded {
          width: 100%;
          padding: 15px !important;
        }
        .all-loaded__text {
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding: 10px 20px;
          color: #888;
          background-color: #fff;
          border-radius: 10px;
          width: 100%;
          margin: 0 auto;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .all-loaded {
            margin: 15px auto;
            padding: 0px !important;
          }
        }
      `}</style>
    </div>
  );
};

EndOfFeed.propTypes = {
  show: PropTypes.bool,
};

export default EndOfFeed;
