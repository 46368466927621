import DOMPurify from 'isomorphic-dompurify';

export const sanitiseText = (text, config) => {
  return DOMPurify.sanitize(text, config);
};

export const ALLOW_SCRIPT_TAGS_CONFIG = {
  ADD_TAGS: ['script'],
  FORCE_BODY: true,
};
