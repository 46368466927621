import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SHOW_PRICE_TYPES } from '../../../config/constants/deals';
import { SPLAT_MAX_WIDTH_PX } from '../../../config/setup/setup';
import { UP_TO } from '../../../config/text/text';
import {
  showPriceType,
  showPrice,
  getPrice,
  getText,
  getIsChooseYourProductDeal,
  showPerNight,
} from '../../../helpers/deals';
import useClientSideEffect from '../../../helpers/useClientSideEffect';
import ThemeContext from '../../../providers/ThemeProvider';

const NormalPriceSplat = ({
  discount = false,
  className,
  coinFlipAnimation,
  deal = {},
  forcePrice = true,
  visible = true,
  isHideIfNoDiscount = true,
  isHideSplat = false,
  showVipSplat = false,
  secondCheckout = false,
  rowSelection,
  columnSelection,
  isProductPage = false,
}) => {
  const theme = useContext(ThemeContext);
  const priceContainer = useRef();
  const [shrinkPrice, setShrinkPrice] = useState(false);
  const [transform, setTransform] = useState(false);

  const isChooseYourProductDeal = getIsChooseYourProductDeal(deal);
  const priceType = showPriceType(
    deal,
    discount,
    forcePrice,
    isHideIfNoDiscount,
  );
  const defaultPrice = getPrice({
    columnSelection,
    deal,
    forceDecimals: true,
    priceType,
    rowSelection,
  });

  const nonProductPagePrice = defaultPrice;
  const [price, setPrice] = useState(defaultPrice);

  useEffect(() => {
    // Check if it's a product page and columnSelection is greater than 0 or if its a isChooseYourProductDeal
    if (isProductPage && (columnSelection > 0 || isChooseYourProductDeal)) {
      setPrice(
        getPrice({
          columnSelection,
          deal,
          forceDecimals: true,
          priceType,
          rowSelection,
        }),
      );
    }
  }, [isProductPage, columnSelection, rowSelection]);

  useClientSideEffect(() => {
    setShrinkPrice(priceContainer?.current?.offsetWidth > SPLAT_MAX_WIDTH_PX);
  }, [priceContainer]);

  // hide splat
  if (priceType === SHOW_PRICE_TYPES.hidden || isHideSplat) return null;

  useEffect(() => {
    if (showVipSplat && coinFlipAnimation) {
      const timeoutId = setTimeout(() => {
        setTransform(true);
      }, 3_000);

      return () => clearTimeout(timeoutId);
    }
  }, [showVipSplat, coinFlipAnimation]);

  return (
    <div
      className={classNames('splat', className, {
        'splat--discount': discount,
        'splat--discount-indicative':
          priceType === SHOW_PRICE_TYPES.showDiscountIndicative,
        'splat--price': priceType !== SHOW_PRICE_TYPES.showDiscountIndicative,
        'splat--visible': visible,
      })}
    >
      <div className="splat__text">
        <p className="splat__now">
          {getText({ deal, priceType, rowSelection, secondCheckout })}
        </p>
        {priceType === SHOW_PRICE_TYPES.showDiscountIndicative && (
          <p className="splat__upto">{UP_TO}</p>
        )}
        <p
          className={classNames('splat__price', {
            'splat__price--small': shrinkPrice,
          })}
          dangerouslySetInnerHTML={{
            __html: isProductPage ? price : nonProductPagePrice,
          }}
          ref={priceContainer}
        />
        {showPerNight({ deal, priceType }) && (
          <p className="splat__per-night">Per Night</p>
        )}
        {priceType === SHOW_PRICE_TYPES.showOriginalPrice && !isMobile && (
          <p
            className="splat__original-price"
            dangerouslySetInnerHTML={{
              __html: showPrice({
                currency: deal.currency,
                dealPrice: deal.originalPrice,
                forceDecimals: true,
                pricePerPerson: false,
              }),
            }}
          />
        )}
      </div>
      <style jsx>{`
        .splat {
          width: 85px;
          height: 85px;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(${theme.images.dealsplat}) no-repeat;
          background-position: 24px 24px;
          background-size: 131px;
          z-index: 100;
          cursor: pointer;
          visibility: hidden;
          pointer-events: none;
          transform: ${transform ? 'rotateY(90deg)' : 'rotateY(0deg)'};
          transition: ${transform ? 'all 0.4s ease-in' : ''};
        }

        .splat--visible {
          visibility: visible;
        }

        .splat__text {
          z-index: 999;
          position: absolute;
          bottom: ${secondCheckout ? '35px' : '4px'};
          right: 0;
          color: #ffffff;
          margin: 0;
          text-align: center;
          line-height: 1em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 54px;
          min-height: 38px;
          font-size: 17px;
        }

        .splat__now {
          font-size: 0.7em;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 1.2em;
          margin: 0;
        }

        .splat__upto {
          font-size: 0.6em;
          text-transform: uppercase;
          line-height: 1em;
          margin: 0;
        }

        .splat__price {
          font-weight: bold;
          line-height: 1.2em;
          margin: 0;
        }

        .splat__per-night {
          font-size: 9px;
          line-height: 8px;
          margin: -3px 0 1px 0;
        }

        :global(.cols-50 .splat__price--small) {
          font-size: 16px;
        }

        .xs .splat__text .splat__price--small {
          font-size: 14px;
        }

        .splat__original-price {
          position: relative;
          font-size: 0.6em;
          line-height: 1em;
          margin: 0;
        }

        .splat__original-price:before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          right: 0;
          width: 120%;
          margin-left: -10%;
          border-top: 1px solid;
          -webkit-transform: rotate(-11deg);
          -ms-transform: rotate(-11deg);
          transform: rotate(-11deg);
        }

        .splat :global(.price__cents) {
          font-size: 0.6em;
          line-height: 0;
        }

        .splat :global(.price__pp) {
          font-size: 0.5em;
          font-weight: normal;
          vertical-align: super;
          line-height: 0;
        }
        .bottom-right {
          bottom: initial;
          background-position: 10px -40px;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .splat {
            background-position: 26px 20px;
            background-size: 100px;
          }

          .splat__text {
            font-size: 19px;
            min-height: 45px;
          }

          .top-right {
            top: 0;
            bottom: initial;
            background-position: 23px -48px;
          }
          .bottom-right {
            bottom: 0;
            bottom: initial;
            background-position: 10px -35px;
          }
          .top-right .splat__text {
            top: 2px;
            bottom: auto;
            min-height: 51px;
          }

          .xs {
            background-position: 20px 24px;
            background-size: 80px;
          }
          .xs .splat__text {
            font-size: 16px;
            min-width: 48px;
            min-height: 38px;
          }
          .top-right.xs {
            background-position: 32px -32px;
          }
          .top-right.xs .splat__text {
            min-height: 48px;
          }

          .xl {
            width: 120px;
            height: 120px;
            background-position: 34px 34px;
            background-size: 120px;
          }
          .xl .splat__text {
            font-size: 24px;
            min-height: 70px;
            min-width: 80px;
          }
        }

        .main-deal--mobile {
          top: 0;
          bottom: initial;
          background-size: 80px;
          background-position: 16px -20px;
        }
        .main-deal--mobile .splat__text {
          top: 2px;
          bottom: auto;
          min-height: 60px;
          min-width: 60px;
          font-size: 20px;
        }
        .main-deal--mobile--product-page {
          bottom: initial;
          background-size: 110px;
          background-position: 0px -30px;
        }
        .main-deal--mobile--product-page .splat__text {
          top: 2px;
          bottom: auto;
          min-height: 60px;
          min-width: 60px;
          font-size: 20px;
        }
        .full {
          width: 160px;
          height: 114px;
          background-size: contain;
          background-position: 28px 0px;
        }
        .full .splat__text {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
        .full .splat__price {
          font-size: 32px;
        }
        .full .splat__now {
          font-size: 0.8em;
        }
        .full .splat__upto {
          font-size: 0.7em;
        }
        .full .splat__original-price {
          font-size: 1em;
        }
      `}</style>
    </div>
  );
};

NormalPriceSplat.propTypes = {
  className: PropTypes.string,
  coinFlipAnimation: PropTypes.bool,
  columnSelection: PropTypes.number,
  deal: PropTypes.object,
  discount: PropTypes.bool,
  forcePrice: PropTypes.bool,
  isHideIfNoDiscount: PropTypes.bool,
  isHideSplat: PropTypes.bool,
  rowSelection: PropTypes.number,
  secondCheckout: PropTypes.bool,
  showVipSplat: PropTypes.bool,
  visible: PropTypes.bool,
};

export default NormalPriceSplat;
