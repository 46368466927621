export const updateProductPageDetails = (
  column,
  isChooseYourProductDeal,
  columnSelection,
) => {
  if (
    column.items.length === 1 ||
    isChooseYourProductDeal ||
    (column.items.length > 1 && columnSelection > 0)
  ) {
    return true;
  }

  return false;
};
