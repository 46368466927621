/* eslint-disable jsx-a11y/no-onchange */
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import ThemeContext from '../../../providers/ThemeProvider';
import FilterButton from './FilterButton';
import {
  FilterButtonStyle,
  FilterIconStyle,
  FilterButtonWrapper,
} from './FilterButtonStyle';

const FilterSelect = ({ selected, options, onChange, label = '' }) => {
  if (!options || !options.length) return null;

  const theme = useContext(ThemeContext);
  const [open, setOpen] = useState(false);

  const onChangeAction = (value) => {
    setOpen(false);
    onChange(value);
  };

  const selectedValue = options.find((item) => item.value === selected);
  const optionSelectedName = selectedValue ? selectedValue.name : label;

  const mobileSelect = (
    <div style={FilterButtonWrapper}>
      <select
        onChange={(e) => onChangeAction(e.target.value)}
        onKeyPress={(e) => onChangeAction(e.target.value)}
        style={FilterButtonStyle({ open: false, theme })}
        tabIndex={0}
        value={selected}
      >
        {label && (
          <option key={`select-menu--${label}`} label={label} value={-1}>
            {label}
          </option>
        )}
        {options.map((item, index) => (
          <option
            key={`select-menu--${item.value}-${index}`}
            label={item.name}
            value={item.value}
          >
            {item.name}
          </option>
        ))}
      </select>
      <div style={FilterIconStyle({ open: false, theme })}>
        <FontAwesomeIcon
          className="timer__icon"
          icon={!open ? faAngleDown : faAngleUp}
          style={{ height: 25, width: 18 }}
        />
      </div>
    </div>
  );

  const desktopSelect = (
    <>
      <FilterButton
        label={optionSelectedName}
        onClick={() => setOpen((o) => !o)}
        open={open}
      >
        <ul className="select-menu__list">
          {options.map((item, index) => {
            return (
              <li
                aria-selected={item.value === selected}
                className={item.value === selected ? 'selected' : ''}
                key={`select-menu__li--${item.value}-${index}`}
                label={item.name}
                onClick={() => onChangeAction(item.value)}
                onKeyPress={() => onChangeAction(item.value)}
                role="option"
                tabIndex={0}
                value={item.value}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </FilterButton>
      <style jsx>{`
        .select-menu__list {
          font-size: 15px;
          list-style: none;
          padding: 0 40px 10px 32px;
        }
        .select-menu__list li {
          cursor: pointer;
          margin-top: 7px;
        }
        .select-menu__list li:hover {
          color: ${theme.colors.primary};
        }
        .select-menu__list li.selected {
          color: ${theme.colors.filterslider};
        }
      `}</style>
    </>
  );

  if (isMobile) return mobileSelect;

  return desktopSelect;
};

FilterSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FilterSelect;
