import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SUBCAT_REDIRECTED } from '../config/text/text';
import { getCategoryName } from '../helpers/facetedNavigation';
import ThemeContext from '../providers/ThemeProvider';

const SubCatRedirectedBanner = ({ redirectToCategory }) => {
  const theme = useContext(ThemeContext);
  const [navigation] = useSelector((state) => [state.navigation.list]);
  const categoryName = getCategoryName(navigation, redirectToCategory);

  if (!redirectToCategory) return null;

  return (
    <>
      <div className="subcat-redirected">{SUBCAT_REDIRECTED(categoryName)}</div>
      <style jsx>{`
        .subcat-redirected {
          padding: 12px;
          color: ${theme.commoncolors.yellow};
          background-color: ${theme.commoncolors.lightyellow};
          border: 1px solid ${theme.commoncolors.yellow};
          margin: 0 0 16px;
          text-align: center;
          width: 100%;
        }
      `}</style>
    </>
  );
};

export default SubCatRedirectedBanner;
