import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';

const ArrowButton = ({ onClick, orientation, isOffset, extraClasses }) => {
  const theme = useContext(ThemeContext);

  const getPath = () => {
    if (orientation === 'next')
      return (
        <FontAwesomeIcon
          icon={faAngleRight}
          style={{ height: 28, width: 'auto' }}
        />
      );

    return (
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{ height: 28, width: 'auto' }}
      />
    );
  };

  const orientationClass =
    orientation && orientation === 'prev' ? 'previous' : 'next';
  const ariaLabel = orientation && orientation === 'prev' ? 'Previous' : 'Next';

  return (
    <div className="arrow-button-wrapper">
      <button
        aria-label={ariaLabel}
        className={classNames(
          'flickity-button',
          'flickity-prev-next-button',
          orientationClass,
          { offset: isOffset },
          extraClasses,
        )}
        onClick={onClick}
        type="button"
      >
        {getPath()}
      </button>
      <style jsx>{`
        .flickity-prev-next-button {
          position: absolute;
          padding: 0;
          top: 50%;
          margin: -22px 0 0;
          z-index: 3;
          width: 32px;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
          height: 44px;
          border: none;
          background-color: ${theme.colors.arrowbackground};
          opacity: 0.75;
          cursor: pointer;
        }

        .carousel-thumbs-next-arrow {
          background-color: ${theme.colors.arrowbackground};
        }

        .flickity-prev-next-button.next {
          right: 0px;
          border-radius: 100% 0 0 100%;
        }

        .flickity-prev-next-button.previous {
          left: 0px;
          border-radius: 0 100% 100% 0;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .offset {
            top: 70%;
          }
        }

        @media (max-width: ${theme.breakpoints.mdDown}) {
          .flickity-prev-next-button {
            width: 48px;
            height: 48px;
            margin: -24px 0 0;
          }
        }
      `}</style>
      <style global jsx>{`
        .arrow-button-wrapper .flickity-button svg {
          color: ${theme.colors.arrowcolor};
          fill: ${theme.colors.arrowcolor};
        }

        .flickity-prev-next-button.next svg {
          margin-left: 5px;
        }

        .flickity-prev-next-button.previous svg {
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

ArrowButton.propTypes = {
  extraClasses: PropTypes.string,
  isOffset: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  orientation: PropTypes.string,
};

ArrowButton.defaultProps = {
  extraClasses: '',
  isOffset: false,
  orientation: 'prev',
};

export default ArrowButton;
