import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazyload';
import {
  DEFAULT_DEAL_IMG_HEIGHT,
  DEFAULT_DEAL_IMG_WIDTH,
} from '../../../../config/setup/setup';
import { getIsLeadGen } from '../../../../helpers/deals';
import ImageComponent from './ImageComponent';

const CarouselImage = ({
  deal = {},
  disableClickAction = false,
  height = DEFAULT_DEAL_IMG_HEIGHT,
  href = null,
  image = {},
  index = 0,
  lazy = false,
  load = false,
  onClick = undefined,
  secondCheckout = false,
  showImageOverlays = false,
  showScrim = true,
  imageVariant,
  width = DEFAULT_DEAL_IMG_WIDTH,
}) => {
  const [active, setActive] = useState(() => load);
  const isLeadGen = getIsLeadGen(deal);
  const leadGenNumber = deal?.leadGen?.number;
  useEffect(() => {
    if (load && !active) {
      setActive(load);
    }
  }, [load, active]);

  if (!active) {
    return null;
  }

  let wrapLinkHref = onClick ? undefined : href;
  if (isLeadGen && leadGenNumber && isMobile) {
    wrapLinkHref = `tel: ${leadGenNumber}`;
  }

  const onAction = () => {
    if (onClick && !disableClickAction) {
      onClick();
    }
  };

  return (
    <>
      <div
        className={classNames('single-image', {
          'single-image--isLeadGen': isLeadGen,
        })}
        onClick={onAction}
        onKeyPress={(event) => event.key === 'Enter' && onAction()}
        role="button"
        tabIndex="0"
      >
        {lazy ? (
          <LazyLoad offset={500} once>
            {
              <ImageComponent
                deal={deal}
                height={height}
                image={image}
                imageVariant={imageVariant}
                index={index}
                isLeadGen={isLeadGen}
                secondCheckout={secondCheckout}
                showImageOverlays={showImageOverlays}
                showScrim={showScrim}
                width={width}
                wrapLinkHref={wrapLinkHref}
              />
            }
          </LazyLoad>
        ) : (
          <ImageComponent
            deal={deal}
            height={height}
            image={image}
            imageVariant={imageVariant}
            index={index}
            isLeadGen={isLeadGen}
            secondCheckout={secondCheckout}
            showImageOverlays={showImageOverlays}
            showScrim={showScrim}
            width={width}
            wrapLinkHref={wrapLinkHref}
          />
        )}
      </div>
      <style jsx>{`
        .single-image {
          display: block;
          cursor: ${disableClickAction ? 'default' : 'pointer'};
          height: 0;
          width: 100%;
          padding-bottom: 66.9%;
        }
        .single-image.single-image--isLeadGen {
          cursor: default;
        }
        .single-image:focus-visible {
          outline-offset: -5px;
        }
        :global(.single-image__picture) {
          display: block;
        }
        :global(.single-image__image) {
          height: auto;
          width: 100%;
        }
      `}</style>
    </>
  );
};

CarouselImage.propTypes = {
  deal: PropTypes.object,
  disableClickAction: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  href: PropTypes.string,
  image: PropTypes.object,
  imageVariant: PropTypes.string,
  index: PropTypes.number,
  lazy: PropTypes.bool,
  load: PropTypes.bool,
  onClick: PropTypes.func,
  secondCheckout: PropTypes.bool,
  showImageOverlays: PropTypes.bool,
  showScrim: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CarouselImage;
