export const getDealTitle = ({ deal, isVipUser }) => {
  if (isVipUser && deal?.vipTitle) return deal?.vipTitle;

  return deal?.title;
};

export const getDealHeadline = ({ deal, isVipUser }) => {
  if (isVipUser && deal?.vipHeadline) return deal?.vipHeadline;

  return deal?.headline;
};

export const getVipExclusiveTag = ({ deal, isVipUser }) => {
  return isVipUser && deal?.display?.vipExclusive;
};
