import classNames from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { VIP_EXCLUSIVE_TAG } from '../../../config/constants/images';
import {
  DEAL_PAGE_PATH_QUALIFIERS,
  PAGE_TYPES,
  VIP_QUALIFIERS,
} from '../../../config/constants/page-types';
import { parseWowcherPath } from '../../../helpers/url';
import ThemeContext from '../../../providers/ThemeProvider';

const VipExclusiveTag = ({ className, visible, isTravelDeal }) => {
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const path = router?.asPath || 'other';
  const pathUrl = parseWowcherPath(path);

  const isVipHubPage = VIP_QUALIFIERS.includes(pathUrl.pageType);
  const isDealPage = DEAL_PAGE_PATH_QUALIFIERS.includes(pathUrl.pageType);
  const isSearchPageVipFilter =
    PAGE_TYPES.search.includes(pathUrl.pageType) && router.query?.vip;

  // Show Vip Splat only on Deal pages, Vip Hub and
  // search page only when vipSearch Filter is on.
  const exclusiveVisiblePages =
    isVipHubPage || isDealPage || isSearchPageVipFilter;

  return (
    <>
      {exclusiveVisiblePages || visible ? (
        <div
          className={classNames(
            'vip-exclusive-tag',
            { 'exclusive--travel': isTravelDeal },
            className,
          )}
        >
          <img
            alt="VIP Exclusive Tag"
            className={classNames('exclusive-img', className)}
            src={VIP_EXCLUSIVE_TAG[process.env.NEXT_PUBLIC_BRAND]}
          />
        </div>
      ) : null}
      <style jsx>{`
        .vip-exclusive-tag {
          display: block;
          position: absolute;
          top: 5px;
          left: 5px;
          isolation: isolate;
          z-index: 999;
        }
        .exclusive-img {
          width: 115px;
          object-fit: contain;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .vip-exclusive-tag {
            position: absolute;
            top: unset;
            left: unset;
            bottom: 10px;
            right: 10px;
          }
          .exclusive--travel {
            bottom: 70px;
            right: 160px;
          }
          .exclusive-img {
            width: 150px;
            object-fit: contain;
          }
          .size--xs {
            position: absolute;
            width: 100px;
            bottom: ${isVipHubPage ? '18px' : '11px'};
            right: 4px;
          }
          .size--md {
            width: 135px;
            bottom: 10px;
            right: 80px;
          }
          .tag-before-splat {
            position: absolute;
            right: 45px;
            bottom: 4px;
          }
          .tag-before-splat--lg {
            width: 130px;
            right: 120px;
          }
        }
        .travel-exculsive-tag {
          display: inline;
          position: inherit;
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .size--md {
            right: 85px;
          }

          .exclusive--travel {
            bottom: 100px;
            right: 170px;
          }
        }
      `}</style>
    </>
  );
};

VipExclusiveTag.propTypes = {
  className: PropTypes.string,
  isTravelDeal: PropTypes.bool,
  visible: PropTypes.bool,
};

export default VipExclusiveTag;
