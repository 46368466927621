import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import { HYPER_LOCAL_SEO_TITLE } from '../config/text/text';
// import { stripHtml } from './stripHtml';

export const hyperLocalSeo = (router, facetedNavigation) => {
  const [, secondSlug, thirdSlug] = router.query.slug;

  const areaSlug = thirdSlug || secondSlug;
  const subCategorySlug = thirdSlug ? secondSlug : null;

  const hyperLocalNavMatch = facetedNavigation?.navigations?.find(
    (item) => item.shortName === subCategorySlug,
  );
  const areaName = upperFirst(areaSlug.replace(/-/g, ' '));

  const hyperLocalSeoTitle = hyperLocalNavMatch?.seoFriendlyName
    ? HYPER_LOCAL_SEO_TITLE(
        hyperLocalNavMatch.seoFriendlyName.toLowerCase(),
        startCase(areaName),
      )
    : HYPER_LOCAL_SEO_TITLE(facetedNavigation.seoFriendlyName, areaName);
  const hyperLocalSeoText = hyperLocalNavMatch?.seoText
    ? hyperLocalNavMatch.seoText
    : facetedNavigation.seoText;

  return {
    hyperLocalSeoText,
    hyperLocalSeoTitle,
    rawSeoFriendly:
      hyperLocalNavMatch?.seoFriendlyName || facetedNavigation?.seoFriendlyName,
  };
};
