import startCase from 'lodash/startCase';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { parseWowcherPath } from './url';

export const getTopLevelNavItem = (name, navigation, locations) => {
  if (!name || !navigation || !locations) {
    return null;
  }
  const entry = navigation.find((nav) => nav.shortName === name);
  // look for the entry in the nav
  if (entry) {
    return {
      linkText: entry.linkText,
      url: entry.url,
    };
  }
  // if it's not there it could be a location so check those
  const location = locations.find((loc) => loc.shortName === name);
  if (location) {
    return {
      linkText: location.name,
      url: '/deals/' + location.shortName,
    };
  }

  return null;
};

export const getSubLevelNavItem = (path, navigation) => {
  if (!path || !navigation) {
    return [];
  }

  const out = [];
  let stage = navigation;

  for (const part of path) {
    if (!stage || stage.length === 0) {
      return out;
    }
    const entry = stage.find((nav) => nav.shortName === part);
    if (entry) {
      out.push({
        linkText: entry.linkText,
        url: entry.url,
      });
      stage = entry.subNavigations;
    } else {
      return out;
    }
  }

  return out;
};

// TODO: FEF-2026 - this should be refactored
export const getBreadcrumbs = (path, navigation, locations, deal) => {
  if (!path || !navigation || !locations) {
    return [];
  }

  // remove any query/hash and split into parts
  const parts = path
    .split('?')[0]
    .split('#')[0]
    .split('/')
    .filter((p) => p !== '');

  const isDealPage = parts[0] === 'deal';
  const { details } = parseWowcherPath(path);
  const crumbs = [];

  if (!details.isTravel) {
    // shop or location (travel is techincally a cat not root so ignore)
    const root = getTopLevelNavItem(parts[1], navigation, locations);
    if (root) {
      crumbs.push(root);
    }
  }

  const catParts = parts.slice(
    details.isTravel ? 1 : 2,
    isDealPage ? -2 : parts.length, // the last 2 parts on a deal page are always dealId & dealSlug
  );
  crumbs.push(...getSubLevelNavItem(catParts, navigation));

  if (deal && deal.headline) {
    crumbs.push({ linkText: deal.headline, url: '#' });
  }

  return crumbs;
};

export const getHyperLocalBreadcrumbs = (slugs) => {
  const isHubPage = slugs.length === 1;
  const [firstSlug, secondSlug, thirdSlug] = slugs;

  const areaSlug = thirdSlug ? thirdSlug : secondSlug;
  const subCategorySlug = thirdSlug ? secondSlug : null;

  const subCategoryBreadcrumb = {
    linkText: startCase(secondSlug),
    url: `/near-me/${firstSlug}/${secondSlug}/${areaSlug}`,
  };

  const areaAndCategoryBreadcrumb = [
    { linkText: startCase(areaSlug), url: `/near-me/${areaSlug}` },
    {
      linkText: startCase(firstSlug),
      url: `/near-me/${firstSlug}/${areaSlug}`,
    },
  ];

  return [
    { linkText: 'Near me', url: '/near-me/hub' },
    ...(isHubPage
      ? [{ linkText: startCase(firstSlug), url: `/near-me/${firstSlug}` }]
      : areaAndCategoryBreadcrumb),
    ...(subCategorySlug ? [subCategoryBreadcrumb] : []),
  ];
};

export const useBreadcrumbs = (deal) => {
  const router = useRouter();
  const [navigation, locations] = useSelector((state) => [
    state.navigation.list,
    state.locations.locations,
  ]);

  const { details } = parseWowcherPath(router.asPath);

  const hyperLocalPath = router?.query?.path
    ? [router.query.path]
    : router?.query?.slug;

  return details?.isHyperlocal
    ? getHyperLocalBreadcrumbs(hyperLocalPath)
    : getBreadcrumbs(router.asPath, navigation, locations, deal);
};

export const getLastElement = (index, max, item) => {
  if (index + 1 >= max) return <span itemProp="name">{item?.linkText}</span>;

  return (
    <a href={item?.url ?? ''} itemProp="item">
      <span itemProp="name">{item?.linkText}</span>
    </a>
  );
};
