import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { trackEvent, updateDod } from '../../helpers/analytics';
import { isFindPage, isFindListItem } from '../../helpers/find';
import ThemeContext from '../../providers/ThemeProvider';

const onClickHandler = (name, url) => {
  if (isFindPage(url)) {
    updateDod({ findPageName: name });
    trackEvent('Find page nav click');
  }
};

const FacetedNavigationListItem = ({ listItem }) => {
  const theme = useContext(ThemeContext);

  const linkClass = listItem.isActive
    ? 'faceted-navigation__link faceted-navigation__link--active'
    : 'faceted-navigation__link';

  const listItemUrl = isFindListItem(listItem.url)
    ? listItem.url.replace('deals', 'find')
    : listItem.url;

  return (
    <>
      <li className="faceted-navigation__item" key={listItem.url}>
        <Link as={listItemUrl} href={listItemUrl ?? ''}>
          <a
            className={linkClass}
            onClick={() => onClickHandler(listItem.linkText, listItem.url)}
            onKeyDown={() => onClickHandler(listItem.linkText, listItem.url)}
            role="link"
            tabIndex="0"
          >
            {listItem.linkText}
          </a>
        </Link>
        {listItem.count > 0 && ` (${listItem.count})`}
      </li>
      <style jsx>{`
        .faceted-navigation__item {
          font-size: 13px;
          line-height: 26px;
          text-align: left;
        }
        .faceted-navigation__link {
          color: ${theme.colors.primaryonwhite};
        }
        .faceted-navigation__link--active {
          font-weight: bold;
          text-decoration: underline;
        }
        @media only screen and (min-width: ${theme.breakpoints.mdUp}) {
          .faceted-navigation__item {
            font-size: 16px;
          }
        }
      `}</style>
    </>
  );
};

export default FacetedNavigationListItem;

FacetedNavigationListItem.propTypes = {
  listItem: PropTypes.object.isRequired,
};
