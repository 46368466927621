import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { SEE_LESS, SEE_MORE } from '../../../config/text/text';
import ThemeContext from '../../../providers/ThemeProvider';

const MoreButton = ({
  centeredButton = false,
  expandedText,
  collapsedText,
  expanded,
  onToggle,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <button
        aria-label={expanded ? expandedText : collapsedText}
        className="more-button"
        onClick={onToggle}
        type="button"
      >
        <span>{expanded ? expandedText : collapsedText}</span>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            marginLeft: 4,
            transform: expanded && `rotateZ(180deg)`,
          }}
        />
      </button>
      <style jsx>{`
        .more-button {
          color: ${theme.colors.primary};
          border: none;
          background-color: transparent;
          display: ${centeredButton ? 'inline-block' : 'flex'};
          align-items: center;
          padding: 0;
        }
      `}</style>
    </>
  );
};

MoreButton.propTypes = {
  centeredButton: PropTypes.bool,
  collapsedText: PropTypes.string,
  expanded: PropTypes.bool,
  expandedText: PropTypes.string,
  onToggle: PropTypes.func,
};

MoreButton.defaultProps = {
  collapsedText: SEE_MORE,
  expanded: false,
  expandedText: SEE_LESS,
  onToggle: () => false,
};

export default MoreButton;
