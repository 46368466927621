export const EXCLUDED_CATEGORIES = ['learning', 'food-drink'];
export const EXCLUDED_PAGES = [
  '/activities-entertainment/other',
  '/beauty/beauty-electricals',
  '/beauty/haircare-products',
  '/health-fitness/activity-trackers',
  '/health-fitness/home-gyms',
  '/health-fitness/other',
  '/health-fitness/sport-equipment',
  '/health-fitness/sportswear',
  '/health-fitness/suncare',
  '/pets/accessories',
  '/pets/beds',
  '/pets/food-treats',
  '/pets/other',
];

export const showPopularLocationModule = ({
  isHyperLocal,
  isLocationPage,
  isTravel,
  hyperlocalLocationsInCurrentArea,
  category,
  subCategory,
  categoryName,
  areaName,
}) => {
  // show for all hyperlocal pages
  if (isHyperLocal) {
    return true;
  }

  // don't show on location/travel pages
  if (isLocationPage || isTravel) {
    return false;
  }

  // basic checks we have the info we need to display
  if (
    hyperlocalLocationsInCurrentArea.length === 0 ||
    !areaName ||
    !categoryName
  ) {
    return false;
  }

  // check for excluded categories
  if (EXCLUDED_CATEGORIES.includes(category)) {
    return false;
  }

  // check the page isn't excluded
  const path = `/${[category, subCategory].filter((entry) => entry).join('/')}`;
  if (EXCLUDED_PAGES.includes(path)) {
    return false;
  }

  return true;
};

export const getNearMeLinkUrlFromConfig = ({
  category,
  subCategory,
  location,
}) => {
  const path = [category, subCategory, location]
    .filter((item) => item)
    .join('/');
  if (path.length) {
    return '/near-me/' + path;
  } else {
    // fallback should never happen but if it does, send to the hub
    return '/near-me/hub';
  }
};

const toLowerCase = (string) =>
  string
    ?.split(' ')
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join(' ');

export const moduleTitle = ({
  isLocationPage,
  categoryName,
  isHyperlocal,
  areaName,
}) => {
  return `Find More ${isLocationPage ? 'Deals' : categoryName} ${
    isHyperlocal ? 'in' : 'near'
  } ${areaName}`;
};

export const moduleSubtitle = ({ categoryName, isHyperlocal, areaName }) => {
  const category = toLowerCase(categoryName);

  return `There are even more deals${category ? ' for ' + category : ''} ${
    isHyperlocal ? 'in' : 'near'
  } ${areaName} for you to try`;
};
